// export const fieldsMap = {
//     field_monitor_name: { label: "Field Monitor Name", type: "auto" },
//     sub_activity: { label: "Sub-activity", type: "dropdown" },
//     debris_type: { label: "Debris Type", type: "dropdown" },
//     load_date: { label: "Load Date", type: "auto" },
//     load_time: { label: "Load Time", type: "auto" },
//     confirm_debris_type: { label: "Confirm Debris Type", type: "popup_message" },
//     truck_number: { label: "Truck #", type: "scan" },
//     address: { label: "Address", type: "auto" },
//     disposal_site: { label: "Disposal Site", type: "dropdown" },
//     pass_number: { label: "Pass Number", type: "numeric" },
//     diameter: { label: "Diameter", type: "numeric" },
//     length: { label: "Length", type: "numeric" },
//     hazard_type: { label: "Hazard Type", type: "dropdown" },
//     stumps_extraction: { label: "Stumps Extraction Required", type: "yes_no" },
//     roe: { label: "ROE", type: "yes_no" },
//     roe_reason: { label: "ROE Reason", type: "alphanumeric" },
//     freon_extracted: { label: "Freon Extracted", type: "yes_no" },
//     vin: { label: "VIN/HIN", type: "alphanumeric" },
//     photos_required: { label: "Photos Req'd", type: "quantity_required" },
//     field_monitor_notes: { label: "Field Monitor Notes", type: "alphanumeric" },
//     site_monitor_name: { label: "Site Monitor Name", type: "auto" },
//     debris_site: { label: "Debris Site", type: "auto" },
//     offload_truck_number: { label: "Truck #", type: "scan" },
//     offload_date: { label: "Offload Date", type: "auto" },
//     offload_time: { label: "Offload Time", type: "auto" },
//     offload_confirm_debris_type: { label: "Confirm Debris Type", type: "dropdown" },
//     load_call: { label: "Load Call", type: "numeric" },
//     confirm_quantity: { label: "Confirm Quantity", type: "numeric" },
//     tipping_ticket_number: { label: "Tipping Ticket Number", type: "numeric" },
//     tipping_fee: { label: "Tipping Fee", type: "numeric_currency" },
//     load_call_photos: { label: "Load Call Photos", type: "quantity_required" },
//     site_monitor_notes: { label: "Site Monitor Notes", type: "alphanumeric" },
//     unit_of_measure: { label: "Unit of Measure", type: "auto" },
//     mileage_required: { label: "Mileage Req'd", type: "yes_no" }
// };



export const fieldsMap = {
    field_monitor_name: { label: "Field Monitor Name", type: "auto" },
    sub_activity: { label: "Sub-activity", type: "dropdown" },
    debris_type: { label: "Debris Type", type: "dropdown" },
    load_date: { label: "Load Date", type: "auto" },
    load_time: { label: "Load Time", type: "auto" },
    confirm_debris_type: { label: "Confirm Debris Type", type: "popup_message" },
    truck_number: { label: "Truck #", type: "scan" },
    address: { label: "Address", type: "auto" },
    disposal_site: { label: "Disposal Site", type: "dropdown" },
    pass_number: { label: "Pass Number", type: "numeric" },
    diameter: { label: "Diameter", type: "numeric", unitKey: "cyds_unit" },
    length: { label: "Length", type: "numeric", unitKey: "length_unit" },
    hazard_type: { label: "Hazard Type", type: "dropdown" },
    stumps_extraction: { label: "Stumps Extraction Required", type: "yes_no" },
    roe: { label: "ROE", type: "yes_no" },
    roe_reason: { label: "ROE Reason", type: "alphanumeric" },
    tipping_fees: { label: "Tipping Fees", type: "numeric_currency" },
    freon_extracted: { label: "Freon Extracted", type: "yes_no" },
    vin_number: { label: "VIN/HIN", type: "alphanumeric" },
    photos_required: { label: "Photos Req'd", type: "quantity_required" },
    field_monitor_notes: { label: "Field Monitor Notes", type: "alphanumeric" },
    site_monitor_name: { label: "Site Monitor Name", type: "auto" },
    debris_site: { label: "Debris Site", type: "auto" },
    offload_truck_number: { label: "Truck #", type: "scan" },
    offload_date: { label: "Offload Date", type: "auto" },
    offload_time: { label: "Offload Time", type: "auto" },
    offload_confirm_debris_type: { label: "Confirm Debris Type", type: "dropdown" },
    load_call: { label: "Load Call", type: "numeric" },
    confirm_quantity: { label: "Confirm Quantity", type: "numeric" },
    tipping_ticket_number: { label: "Tipping Ticket Number", type: "numeric" },
    tipping_fee: { label: "Tipping Fee", type: "numeric_currency" },
    load_call_photos: { label: "Load Call Photos", type: "quantity_required" },
    site_monitor_notes: { label: "Site Monitor Notes", type: "alphanumeric" },
    unit_of_measure: { label: "Unit of Measure", type: "auto" },
    mileage_required: { label: "Mileage Req'd", type: "yes_no", unitKey: "miles_unit" },
};


export const rateMatrixFieldsMap = {
    diameter: { label: "Diameter", type: "numeric", unitKey: "cyds_unit" },
    weight: { label: "Weight", type: "numeric", unitKey: "weight_unit" },
    mileage: { label: "Mileage", type: "numeric", unitKey: "miles_unit" },
    unit: { label: "Unit", type: "auto", unitKey: "each_unit" },
}



export const getFieldValidationRules = (type, label, isRequired, isOptional) => {
    let rules =  [];
    if (isRequired) {
        rules = [{ required: true, message: `${label} is required` }];
    } else if (isOptional) {
        rules = [];
    };

    switch (type) {
        case "numeric":
            rules.push({ type: "number", message: `${label} must be a number` });
            break;
        case "alphanumeric":
            rules.push({ pattern: /^[a-zA-Z0-9]+$/, message: `${label} must be alphanumeric` });
            break;
        case "yes_no":
            rules.push({ type: "boolean", message: `${label} must be Yes or No` });
            break;
        case "numeric_currency":
            rules.push({ pattern: /^\d+(\.\d{1,2})?$/, message: `${label} must be a valid currency format (e.g., 100.00)` });
            break;
        case "quantity_required":
            rules.push({ type: "number", min: 1, message: `${label} must be at least 1` });
            break;
        case "vin":
            rules.push({ len: 17, message: `${label} must be exactly 17 characters long` });
            break;
        case "dropdown":
            rules.push({ type: "string", message: `${label} must be a valid selection` });
            break;
        default:
            // Add more cases as needed
            break;
    }

    return rules;
};