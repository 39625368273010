import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ManageProjects from '../../components/projectManagementComponents/manageProjects';
import MapUser from '../../components/projectManagementComponents/mapUser';
import ProjectDetails from '../../components/projectManagementComponents/projectDetails';
import useProjects from '../../components/hooks/useProjects';
import UpdateProjects from '../../components/modals/manageProjects/updateProjects';
import usePermissions from '../../components/hooks/usePermissions';
import { hasPermission } from '../../util/permissionChecker';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { main_api } from '../../api/axiosHelper';
import { pushNotification } from '../../util/notification';

export default function ProjectManagement() {
  const location = useLocation();
  const pageName = location.pathname.split('/')[2];
  const [updateProjectModal, setUpdateProjectModal] = useState(false);
  const [projectDetailsModal, setProjectDetailsModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  const [permissionText, setPermissionText] = useState('');
  const [permissionPopUp, setPermissionPopUp] = useState(false);


  const [projectsDataForTable, setProjectsDataForTable] = useState({});

  const [currentPage, setCurrentPage] = useState(1);

  //------------------ Custom Hooks ---------------------//
  

  const { handleEditProject, handleAddProject } = useProjects();
  
  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  




   //--------------------- Fetch Projects ---------------------//

   const fetchProjects = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
    .then((response) => {
    const result = response.data.results;
    const transformedData = result.map(item => ({
        ...item,
        key: item?.id,
        po_number: item?.po_number || '---',
        name: item?.name || '---',
        event_name: item?.event?.name || '---',
        client_name: item?.client?.name || '---',
        prime_contractor: item?.contractor?.name || '---',
        sub_activity: item?.sub_activity || '---',
        project_identfication_number: item?.project_identfication_number || '---',
        city: item?.city || '---',
        state: item?.state || '---',
        description: item?.description || '---', 
        is_active: item?.is_active,
        created_at: item?.created_at || '---',
        updated_at: item?.updated_at || '---',
      
    }));
    
    setProjectsDataForTable({count: response.data.count, data: transformedData});

    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
};



  //------------------ Functions for Update Projects Modal ---------------------//


    

  const handleEditRow = (value) => {
    if (!hasPermission(assignedPermissionsArray, 'change_project')) {
      setPermissionText("You do not have permission to edit a project");
      setPermissionPopUp(true)
    } else if (value) {
        setSelectedProject(value);
        setSelectedProjectId(value.id);
        setUpdateProjectModal(true);
    }
  };

  const handleAddRow = () => {
    if (!hasPermission(assignedPermissionsArray, 'add_project')) {
      setPermissionText("You do not have permission to add a project");
      setPermissionPopUp(true)
    } else{
      setSelectedProject(null);
      setUpdateProjectModal(true);
    }
  };



  const editProject = (values) => {
    
    handleEditProject(selectedProjectId, values, fetchProjects, setUpdateProjectModal, setProjectDetailsModal, currentPage, setCurrentPage);
  }

  const addProject = (values) => {
    handleAddProject(values, fetchProjects, setUpdateProjectModal);
  }


  return (
   <>
    {/* {pageName === 'manage-project' ? 
        <ManageProjects 
          selectedProject={selectedProject} 
          setSelectedProject={setSelectedProject} 
          setProjectDetailsModal={setProjectDetailsModal}
          setUpdateProjectModal={setUpdateProjectModal}
          updateProjectModal={updateProjectModal}
          handleEditRow={handleEditRow}
          handleAddRow={handleAddRow}
        /> 
        :
        <MapUser/>} */}

    {projectDetailsModal && pageName === 'manage-project' ? 
      <ProjectDetails
        projectDetailsModal={projectDetailsModal}
        setProjectDetailsModal={setProjectDetailsModal}
        selectedProject={selectedProject}
        handleEditRow={handleEditRow}
      />
      :
      pageName === 'manage-project' ? 
        <ManageProjects 
          selectedProject={selectedProject} 
          setSelectedProject={setSelectedProject} 
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
          setProjectDetailsModal={setProjectDetailsModal}
          setUpdateProjectModal={setUpdateProjectModal}
          updateProjectModal={updateProjectModal}
          handleEditRow={handleEditRow}
          handleAddRow={handleAddRow}
          setPermissionPopUp={setPermissionPopUp}
          setPermissionText={setPermissionText}
          projectsDataForTable={projectsDataForTable}
          setProjectsDataForTable={setProjectsDataForTable}
          fetchProjects={fetchProjects}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        /> 
        :
        pageName === 'map-user' &&
        <MapUser
          selectedProjectId={selectedProjectId}
          setSelectedProjectId={setSelectedProjectId}
        />

    }
    {updateProjectModal && <UpdateProjects isModalOpen={updateProjectModal} title={selectedProject ? "Edit Project" : "Add Project"} onFinish={selectedProject ? editProject : addProject  } setModalOpen={setUpdateProjectModal} editProjectValues={selectedProject}/>}
    {permissionPopUp && (
      <PermissionModal 
        text={permissionText}
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    )}
   </>
  )
}
