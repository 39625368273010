import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Divider, Form, Input, InputNumber, Select, Switch } from "antd";
import { CustomModal, FormWrapper } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { countryCodes } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';



export default function UpdateUser({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    editUserValues,
    padding = "20px",
}) {


    const [address, setAddress] = useState("");
    const [addressLatAndLong, setAddressLatAndLong] = useState([null,null]);
    const [active, setActive] = useState(editUserValues?.status || true);
    const [userRole, setUserRole] = useState(editUserValues?.roleData?.id || null);
    const [positionType, setPositionType] = useState(editUserValues?.positionData?.name || null);
    const [roleType, setRoleType] = useState(null);
    const [positionList, setPositionList] = useState([]);
    const [query, setQuery] = useState("");
    const [primeContractorList, setPrimeContractorList] = useState([]);
    const [primeContractor, setPrimeContractor] = useState();
    const [windowSize, setWindowSize] = useState({ height: window.innerHeight, width: window.innerWidth });
    const rolesState = useSelector((state) => state.roles.roles);
    const [page, setPage] = useState(1);
    
    
    
    const roles = rolesState.map((role) => ({
      label: role.name,
      value: role.id,
    }));


    const [form] = Form.useForm();
    
    const fetchData = async ( page = 1,) => {
      
     if (userRole === undefined || userRole === null) {
       return;
     }
    const query = `role=${userRole}`;
      main_api.get(`${adminAPIsEndPoints.LIST_POSITION(query)}&page=${page}`)
      .then((response) => {
        const result = response.data.results;
        const positions = result?.map((position) => ({
          label: position.name,
          value: position.id,
        }));
        const primeContractorValues = positions
        ?.filter((position) => position.label === "Prime Contractor")
        .map((position) => position.value);
      
      
        let fullQuery = query;
        if (primeContractorValues && primeContractorValues.length > 0) {
          fullQuery += `&position=${primeContractorValues[0]}`;
        }
        
        setQuery(fullQuery);
        setPositionList(positions);
      }).catch((error) => {
        pushNotification(error.response.data.detail, "error");
      });
    
    }
    
    // const fetchPrimeContractor = async ( page = 1,) => {
    //   const query = `is_active=true&page=${page}`;
    //   main_api.get(`${adminAPIsEndPoints.LIST_CONTRACTORS(query)}&page=${page}`)
    //   .then((response) => {
    //     const result = response.data.results;
    //     const primeContractors = result?.map((primeContractor) => ({
    //       label: primeContractor.name,
    //       value: primeContractor.id,
    //     }));
        
    //     setPrimeContractorList(primeContractors);
    //   }).catch((error) => {
    //     pushNotification(error.response.data.detail, "error");
    //   });
    
    // }

    const fetchPrimeContractor = async (page = 1) => {
      let query = `is_active=true&page=${page}`;
      main_api.get(adminAPIsEndPoints.LIST_CONTRACTORS(query))
        .then((response) => {
          const result = response.data.results;
    
          // Handle pagination
          if (response.data.next) {
            const urlObj = new URL(response.data.next);
            const params = new URLSearchParams(urlObj.search);
            const pageNumber = params.get('page');
            setPage(pageNumber);
          } else {
            setPage(null);
          }
    
          // Generate options array from API response
          let options = [...primeContractorList, ...result?.map(primeContractor => ({
            label: primeContractor.name.charAt(0).toUpperCase() + primeContractor.name.slice(1),
            value: primeContractor.id,
          }))];
    
          // Ensure selected prime contractors are included
          if (editUserValues?.prime_contractor) {
            const selectedContractor = {
              label: editUserValues?.prime_contractor?.name.charAt(0).toUpperCase() + editUserValues?.prime_contractor?.name.slice(1),
              value: editUserValues?.prime_contractor?.id,
            };
    
            // Ensure the selected contractor is included at the beginning of the options
            options = [selectedContractor, ...options.filter(item => item.value !== selectedContractor.value)];
          }
    
          setPrimeContractorList(options);
        })
        .catch((error) => {
          pushNotification(error?.response?.data?.detail, "error");
        });
    };
    
    useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

    useEffect(() => {
      setUserRole(form.getFieldValue("userRole"));
    }, [form]);


    useEffect(() => {
      fetchData();
      
    }, [userRole]);


   
   useEffect(() => {
    if (editUserValues && editUserValues?.roleData?.type && editUserValues?.positionData?.name) {
      setRoleType(editUserValues?.roleData?.type);
      setPositionType(editUserValues?.positionData?.name);
    }
    }, [editUserValues]);

    useEffect(() => {
        if (editUserValues) {
          
          
          form.setFieldsValue({
            name: editUserValues.name,
            email: editUserValues?.email,
            telephone_number: editUserValues?.phone_number,
            is_active: editUserValues.status,
            password: editUserValues?.password,
            confirm_password: editUserValues?.password,
            role: editUserValues?.roleData?.id,
            position: editUserValues?.positionData?.id,
            // prime_contractor: editUserValues?.prime_contractor?.id,
            // company_name: editUserValues?.additional_data?.company_name,
            prefix: editUserValues?.prefix,
            address: editUserValues?.address,
            latitude: editUserValues?.latitude,
            longitude: editUserValues?.longitude,
        });
        }
    }, [editUserValues]);

    useEffect(() => {
      if (editUserValues) {
        form.setFieldsValue({
          company_name: editUserValues?.additional_data?.company_name,
          prime_contractor: editUserValues?.prime_contractor?.id,
        });
      }
    }, [editUserValues]);

   
    useEffect(() => {
      if(addressLatAndLong){

        let latitude = addressLatAndLong[0] || null;
        form.setFieldValue("latitude", latitude);

        let longitude = addressLatAndLong[1] || null;
        form.setFieldValue("longitude", longitude);
      }
    }, [addressLatAndLong]);
    

    const handleSwitchChange = (checked) => {
      setActive(checked);
      form.setFieldsValue({ is_active: checked }); 
      
    };

    const handleRoleChange = (value,option) => {
      setUserRole(value);
      const type = rolesState.filter((role) => role.name === option.label).map((role) => role.type);
      setRoleType(type[0]);

      setPositionType(null);
      form.setFieldsValue({ position: null });
      
    }

    const handlePositionChange = (value,option) => {
      if (option.label === "Sub Contractor") {
        setQuery(`role=${userRole}`);
      }
      if (option.label === "Sub Contractor" || option.label === "Prime Contractor") {
        setPositionType(option.label);  
      }else{
      setPositionType(null);
      }
    };

    const handlePrimeContractorChange = (value,option) => {
      setPrimeContractor(value);
    };

    const updateData = (values) =>{
      
      onFinish(values, roleType, positionType)
    }

  return (

  <CustomModal  
  open={isModalOpen}
  title={title}
  width="1000px"
  heigth="600px"
  onCancel={() => {
    setModalOpen(false);
    form.resetFields();
  }}
  
  footer={null}
  maskClosable={false}
  // isScrollable={true}
>
  <Divider style={{width:"104.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>

  <Form name="updateUserForm" onFinish={updateData} form={form} layout="vertical" >
    <FormWrapper windowSize={windowSize}>
    <div style={{display:"flex", flexDirection:"row", marginRight:"4px"}}>

    <FormItem 
      name="role" 
      label="User Role" 
      rules={[
        {
          required: true,
          message: "Please select a user role",
        },
      ]}
    >
      <Select placeholder="Select Role" options={roles} style={{width:"260px"}} onChange={handleRoleChange}/>
    </FormItem>
    <FormItem 
      name="position" 
      label="Position Name"
      rules={[
        {
          required: true,
          message: "Please select a position name",
        },
      ]} 
      style={{position:"relative", top:"0px",right:positionType !== "Sub Contractor" ? "167px" : "6px"}}
    >
      <PaginatedSelect fetchData={fetchData} placeholder="Select Position" options={positionList} value={positionType} onChange={handlePositionChange} style={{width:"260px"}}/>
    </FormItem>
    {
      (roleType === "contractor" && positionType === "Sub Contractor")  ? 
    
      (
        <>
        <FormItem 
          name="prime_contractor" 
          label="Prime Contractor"
          rules={[
            {
              required: true,
              message: "Please select your prime contractor",
            },
          ]} 
          style={{position:"relative", top:"0px",right:"14px"}} 
        >
        <PaginatedSelect fetchData={fetchPrimeContractor} placeholder="Select" options={primeContractorList} onChange={handlePrimeContractorChange} pageNumber={page} style={{width:"320px"}}/>

        </FormItem>
        </>
      )
      :
      null
    }
    </div>


<div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
    <FormItem 
      name="telephone_number"
      label="Telephone Number"
      rules={[
        {
          required: true,
          message: "Please enter your phone number",
        },
        {
          pattern: /^[0-9-+() ]*$/,
          message: "Phone number can only contain numbers, spaces, and the characters +, -, and ()",
        },
        {
          min: 10,
          max: 15,
          message: "Phone number must be between 10 and 15 characters",
        },
      ]}
    >
      <Input placeholder='Enter Telephone Number' style={{width:"260px"}}/>
    </FormItem>
    {
      roleType === "contractor" ? 
      (
        <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
          <FormItem 
            name="company_name" 
            label="Company Name"
            rules={[
              {
                required: true,
                message: "Please enter your company name",
              },
              {
                pattern: /^[a-zA-Z0-9 ]*$/,
                message: "Company name can only contain letters and numbers",
              },
              {
                min: 3,
                max: 50,
                message: "Company name must be between 3 and 50 characters",
              }
            ]} 
            style={{position:"relative", top:"0px",right:"50px"}} 
          >
              <Input placeholder="Enter Company Name" style={{width:"260px"}}/>
          </FormItem>
          <FormItem name="prefix" label="Prefix" style={{position:"relative", top:"0px",right:"5px"}}>
              <Input placeholder="Enter Prefix" style={{width:"320px"}} disabled={editUserValues ? true : false}/>
          </FormItem>
        </div>
      )
      
      : null
    }
</div>
    <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight:"8px"}}>
      <FormItem name="name" label="Name"
        rules={[
          {
            required: true,
            message: "Please enter your name",
          },
        ]} 
      >
          <Input placeholder="Enter Name" autoComplete='new-password' style={{width:"260px"}}/>
      </FormItem>
      <FormItem name="email" label="Email"
       rules={[
        {
          required: true,
          type: "email",
          message: "Please enter a valid email address",
        },
      ]}
      style={{position:"relative", top:"0px",right:"122px"}}>
          <Input placeholder="Enter Email" style={{width:"260px"}}/>
      </FormItem>
      <div  style={{display:"flex", flexDirection:"column",}}>

   
    <FormItem name="is_active" label="Status" style={{ position: "relative", top: "0px", right: "244px" }}>
      <CustomSwitch value={active} onChange={handleSwitchChange} />
    </FormItem>

      </div>
    </div>
    <div style={{display:"flex", flexDirection:"row", alignItems:"center", marginRight:"8px"}}>
     {!editUserValues && (<>
     
      <FormItem name="password" label="Password"
        rules={[
          {
            required: true,
            message: "Please enter your password",
          },
          {
            min: 8,
            message: "Password must be at least 8 characters long",
          },
          {
            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/,
            message: "Password must contain uppercase, lowercase, and a number",
          },
        ]}
      >
          <Input.Password placeholder="Enter Password"  style={{width:"260px"}}/>
      </FormItem>
      <FormItem name="confirm_password" 
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords do not match"));
            },
          }),
        ]}
        label="Confirm Password" style={{position:"relative", top:"0px",right:"0px"}}>
          <Input.Password placeholder="Confirm Password" style={{width:"260px"}}/>
      </FormItem>
     </>)}
      <div  style={{display:"flex", flexDirection:"column",}}>

    <span style={{marginRight:"8px"}}>

    <LocationSelector
        address={address}
        setAddress={setAddress}
        addressLatAndLong={addressLatAndLong}
        setAddressLatAndLong={setAddressLatAndLong}
        form={form}
        checked={false}
        label='Address'
        style={{width:"320px"}}
        formItemStyles={{position:"relative", top:"0px",right:"5px"}}
      />
    </span>
      </div>
    </div>


    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
      <FormItem name="latitude" label="Latitude">
          <Input value={addressLatAndLong[0] || null} disabled={true} style={{width:"450px"}}/>
      </FormItem>
      <FormItem name="longitude" label="Longitude">
          <Input value={addressLatAndLong[1] || null} disabled={true} style={{width:"460px"}}/>
      </FormItem>
    </div>

      </FormWrapper>
    <Divider />
    <SaveContainer className="d-flex justify-content-end">
      <CustomButton
        btnText={"Cancel"}
        margin="0px 5px"
        noBackground
        hideIcon={true}
        onClick={() => setModalOpen(false)}
      />
      <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
    </SaveContainer>
  </Form>
</CustomModal>


  )
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

// const FormWrapper = styled.div`
//   height: 585px;
//   width: ${(props) => props?.windowSize?.width >= 1200 ?  "955px" : "575px"};
//   overflow: auto;

//   ::-webkit-scrollbar {
//     width: 0px;
//   }
// `;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 995px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;













