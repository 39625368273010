import { distance } from "@arcgis/core/geometry/geometryEngine";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import { project } from "@arcgis/core/geometry/projection";
import Point from "@arcgis/core/geometry/Point";


export default function distanceCalculator(lat1, lon1, lat2, lon2) {

    const latLng1 = { longitude: lon1, latitude: lat1 }; 
    const latLng2 = { longitude: lon2, latitude: lat2 }; 

    // Create Point objects for each position in WGS84 spatial reference
    const point1 = new Point({
      ...latLng1,
      spatialReference: SpatialReference.WGS84
    });
    const point2 = new Point({
     ...latLng2,
     spatialReference: SpatialReference.WGS84
    });

   const projectedPoints = project([point1, point2], SpatialReference.WebMercator);

   // Calculate the distance in miles between the two projected points
   const [point1Projected, point2Projected] = projectedPoints;
   const distanceInMiles = distance(point1Projected, point2Projected, "miles");


   return distanceInMiles;
}