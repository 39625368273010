import React, { useEffect, useRef, useState } from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';  
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'; // Import GraphicsLayer
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Field from '@arcgis/core/layers/support/Field';
import request from '@arcgis/core/request';

export default function ShapeFile() {
  const mapRef = useRef(null);
  let map = null;
  let view = null;
  const portalUrl = "https://www.arcgis.com";
    

  useEffect(() => {

    if (mapRef.current) {
      // Create a new WebMap instance
      map = new Map({
        basemap: 'streets-navigation-vector',
      });

      view = new MapView({
        container: mapRef.current,
        map: map,
        center: [67.0011, 24.8607], // Longitude, Latitude for Karachi,
        zoom: 14,
        popup: {
          defaultPopupTemplateEnabled: true
        }
      });

      document.getElementById("uploadForm").addEventListener("change", (event) => {
        const fileName = event.target.value.toLowerCase();

        if (fileName.indexOf(".zip") !== -1) {//is file a zip - if not notify user
          generateFeatureCollection(fileName);
        }
        else {
          document.getElementById('upload-status').innerHTML = '<p style="color:red">Add shapefile as .zip file</p>';
        }
      });

      const points = [
        { lat: 30.46721988067733, lon: -83.40817648022524, name: 'Point 1', description: 'Description for Point 1', radius: 5000, status: 'Active' },
        { lat: 30.480000, lon: -84.320000, name: 'Point 2', description: 'Description for Point 2', radius: 5000, status: 'Active' },
        { lat: 30.400000, lon: -84.220000, name: 'Point 3', description: 'Description for Point 3', radius: 5000, status: 'Active' },
        { lat: 30.490000, lon: -84.200000, name: 'Point 4', description: 'Description for Point 4', radius: 5000, status: 'Inactive' },
        { lat: 30.370000, lon: -84.300000, name: 'Point 5', description: 'Description for Point 5', radius: 5000, status: 'Active' }
    ];
    

      const graphicsLayer = new GraphicsLayer();

      // Add each point to the GraphicsLayer
      points.forEach(point => {
        const graphic = new Graphic({
          geometry: {
            type: 'point',
            longitude: point.lon,
            latitude: point.lat,
          },
          symbol: {
            type: 'picture-marker', // Use picture-marker symbol type
            url: 'https://static.arcgis.com/images/Symbols/Shapes/BluePin1LargeB.png', // URL to the custom icon image
            width: '50px',
            height: '50px',
          },
          attributes: {
            name: point.name,
            description: point.description,
            status: point.status,
            longitude: point.lon,
            latitude: point.lat,

          },
          popupTemplate: {
            title: '{name}',
            content: `
              <div>
                <strong>Description:</strong> {description}<br/>
                <strong>Status:</strong> {status}<br/>
                <strong>Coordinates:</strong> [{longitude}, {latitude}]
              </div>
            `,
          },
        });

        graphicsLayer.add(graphic);

        map.add(graphicsLayer);
      
  
      });


      return () => {
        if (view) {
          view.destroy();
        }
      };
    }
  }, []);


  function generateFeatureCollection (fileName) {
    let name = fileName.split(".");
    // Chrome adds c:\fakepath to the value - we need to remove it
    name = name[0].replace("c:\\fakepath\\", "");

    document.getElementById('upload-status').innerHTML = '<b>Loading </b>' + name;

    // define the input params for generate see the rest doc for details
    // https://developers.arcgis.com/rest/users-groups-and-items/generate.htm
    const params = {
      'name': name,
      'targetSR': view.spatialReference,
      'maxRecordCount': 1000,
      'enforceInputFileSizeLimit': true,
      'enforceOutputJsonSizeLimit': true
    };

    // generalize features to 10 meters for better performance
    params.generalize = true;
    params.maxAllowableOffset = 10;
    params.reducePrecision = true;
    params.numberOfDigitsAfterDecimal = 0;

    const myContent = {
      'filetype': 'shapefile',
      'publishParameters': JSON.stringify(params),
      'f': 'json',
    };

    // use the REST generate operation to generate a feature collection from the zipped shapefile
    request(portalUrl + '/sharing/rest/content/features/generate', {
      query: myContent,
      body: document.getElementById('uploadForm'),
      responseType: 'json'
    })
    .then((response) => {
        const layerName = response.data.featureCollection.layers[0].layerDefinition.name;
        document.getElementById('upload-status').innerHTML = '<b>Loaded: </b>' + layerName;
        addShapefileToMap(response.data.featureCollection);
      })
      .catch(errorHandler);
  }

  function errorHandler (error) {
    console.log('1')
    // document.getElementById('upload-status').innerHTML =
    // "<p style='color:red;max-width: 500px;'>" + error.message + "</p>";
  }

  function addShapefileToMap (featureCollection) {
    // add the shapefile to the map and zoom to the feature collection extent
    // if you want to persist the feature collection when you reload browser, you could store the
    // collection in local storage by serializing the layer using featureLayer.toJson()
    // see the 'Feature Collection in Local Storage' sample for an example of how to work with local storage
    let sourceGraphics = [];

    const layers = featureCollection.layers.map((layer) => {

      const graphics = layer.featureSet.features.map((feature) => {
        return Graphic.fromJSON(feature);
      })
      sourceGraphics = sourceGraphics.concat(graphics);
      const featureLayer = new FeatureLayer({
        objectIdField: "FID",
        source: graphics,
        fields: layer.layerDefinition.fields.map((field) => {
        return Field.fromJSON(field);
        })
      });
      return featureLayer;
      // associate the feature with the popup on click to enable highlight and zoom to
    });
    map.addMany(layers);
    view.goTo(sourceGraphics)
    .catch((error) => {
      if (error.name != "AbortError"){
        console.error(error);
      }
    });

    document.getElementById('upload-status').innerHTML = "";
  }


  return <div style={{ height: '70vh' }}>
  <div ref={mapRef} style={{ height: '100%' }}></div>
      <form enctype="multipart/form-data" method="post" id="uploadForm">
            <div class="field">
              <label class="file-upload">
                <span><strong>Add File</strong></span>
                <input type="file" name="file" id="inFile" />
              </label>
            </div>
      </form>
     <span class="file-upload-status" id="upload-status"></span>
    <div id="fileInfo"> </div>

  </div>
}
