import { ReactComponent as ArrowRightIcon } from "../../assets/rawSvg/greaterThanIcon.svg";

// export const administrationMenuData = [
//   {
//     itemList: [
//       {
//         itemName: "Debris Type",
//         itemIcon: <ArrowRightIcon />,
//         active: true,
//       },
//       {
//         itemName: "Truck Type",
//         itemIcon: <ArrowRightIcon />,
//       },
//       {
//         itemName: "Sub-Activity",
//         itemIcon: <ArrowRightIcon />,
//       },
//       {
//         itemName: "Hazard",
//         itemIcon: <ArrowRightIcon />,
//       },
//       {
//         itemName: "User Role",
//         itemIcon: <ArrowRightIcon />,
//       },
//       {
//         itemName: "Position",
//         itemIcon: <ArrowRightIcon />,
//       },
//       {
//         itemName: "Menu Setup",
//         itemIcon: <ArrowRightIcon />,
//       },
//     ],
//   },
// ];


export const administrationMenuData = [
  {
    itemList: [
      {
        key: '0-0',
        itemName: "Debris Type",
        itemIcon: <ArrowRightIcon />,
        active: true,
      },
      {
        key: '0-1',
        itemName: "Truck Type",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-2',
        itemName: "Sub-Activity",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-3',
        itemName: "Hazard",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-4',
        itemName: "User Role",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-5',
        itemName: "Position",
        itemIcon: <ArrowRightIcon />,
      },
      {
        key: '0-6',
        itemName: "Menu Setup",
        itemIcon: <ArrowRightIcon />,
      },
    ],
  },
];

