import React, { useEffect, useState } from 'react'
import { Card, Collapse, Input, Row, Select, Transfer } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../../components/headingComponent/heading';
import CustomButton from '../../components/customButton/customButton';
import SearchInput from '../../components/searchInput/SearchInput';
import { manageProjectsColumns, mapUserColumns, updateMapUserColumns, userManagementColumns } from '../../util/antdTableColumns';
// import { AntdesignTable } from '../../components/antDesignTable/AntdesignTable';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import UpdateUser from '../../components/modals/userManagement/updateUser';
import { pushNotification } from '../../util/notification';
import { useSelector } from 'react-redux';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { status } from '../../util/dropdownData';
import ResetPassword from '../../components/auth/resetPassword';
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import useProjects from '../hooks/useProjects';
import UpdateProjects from '../modals/manageProjects/updateProjects';
import usePermissions from '../hooks/usePermissions';
import PaginatedSelect from '../customSelect/paginatedSelect';
import UpdateMapUser from './updateMapUser';
import DeletePermissionModal from '../modals/permission/deletePermissionModal';
import { hasPermission } from '../../util/permissionChecker';
import PermissionModal from '../modals/permission/permissionModal';

export default function MapUser({selectedProjectId, setSelectedProjectId}) {

  //------------------ Custom Hooks ---------------------//
  

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];   


  //------------------ State Management ---------------------//

  const [projectsOptions, setProjectsOptions] = useState([]);

  const [selectedUser, setSelectedUser] = useState(null);
  const [roleSelected, setRoleSelected] = useState(null);
  const [positionSelected, setPositionSelected] = useState(null);


  const [mapUserTableData, setMapUserTableData] = useState([]);
  const [updateMapUserTableData, setUpdateMapUserTableData] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [positionOptions, setPositionOptions] = useState([]);
  const [countMapUser, setCountMapUser] = useState(0);
  const [countUpdateMapUser, setCountUpdateMapUser] = useState(0);

  const [updateMapUser, setUpdateMapUser] = useState(true);

  const [editedProject, setEditedProject] = useState(null);

  const [availableUserData, setAvailableUserData] = useState([]);
  const [assignedUserData, setAssignedUserData] = useState([]);
  const [targetKeys, setTargetKeys] = useState([]);


  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [permissionText, setPermissionText] = useState('');

  const [deletePermissionModal, setDeletePermissionModal] = useState(false);

  //------------------ Function to Fetch Data ---------------------//

  const fetchMapUser = async (query = '',page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_ASSIGNED_USERS_ON_PROJECT(query)}&page=${page}`)
    .then((response) => {
      setCountUpdateMapUser(response.data.count) 
      const result = response.data.results;
      if (result) {
        
        const transformedUpdateMapUserTableData = result
        .map(item => ({
          key: item?.id,
          name: item?.name || '---',
          position: item?.position?.name || '---',
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setUpdateMapUserTableData(transformedUpdateMapUserTableData);
      }
    }).catch((error) => {
      pushNotification(error.response.data.detail, "error");
    });
  }

 
    

  const fetchAvailableUsers = async (query = '',page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_AVAILABLE_USERS_ON_PROJECT(query)}&page=${page}`)
    .then((response) => {
      // setCountUpdateMapUser(response.data.count) 
      const result = response.data.results;
      if (result) {
        

        const transformMapUserData = result.map(item => ({
          key: item?.id.toString(),
          name: item?.name || '---',
          position: item?.position?.name || '---',
          // assigned: true,
        }))
        .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
        setAvailableUserData(transformMapUserData);
    

      }
    }).catch((error) => {
      pushNotification(error.response.data.detail, "error");
    });
  }




  const fetchPosition = async (page = 1) => {
    if (roleSelected) {
      const query = `role=${roleSelected}`;
      main_api.get(`${adminAPIsEndPoints.LIST_POSITION(query)}&page=${page}`)
      .then((response) => {
        const result = response.data.results;
        const positionOptions = result.map(item => ({
          label: item?.name,
          value: item?.id,
        }));
        setPositionOptions(positionOptions);
      }).catch((error) => {
        pushNotification(error.response.data.detail, "error");
      });
    } else {
      setPositionOptions([]);
    }
  };


  const fetchProjectOptions = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
    .then((response) => {
    const result = response.data.results;
   
    const options = result?.map((item) => {
        item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
        item.value = item.id;
        return item;
    });
    setProjectsOptions(options);


    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
};

  //------------------ Use Effect For Initial Render ---------------------//

  useEffect(() => {
    if (hasPermission(assignedPermissionsArray, 'view_project') || hasPermission(assignedPermissionsArray, 'manage_map_user')) {
      fetchProjectOptions();
    }
  }, []);


  useEffect(() => {
    let query;
    if (roleSelected && positionSelected && selectedProjectId) {
      query = `role=${roleSelected}&position=${positionSelected}&project=${selectedProjectId}`;
      fetchAvailableUsers(query);
    } else {
      setAvailableUserData([])
    }

  }, [roleSelected, positionSelected, selectedProjectId]);


  useEffect(() => {
    fetchPosition();
  }, [roleSelected]);

  //----------------------- Filter -----------------------//

  useEffect(() => {
    const projectId = selectedProjectId;
    if(projectId){
      const query = `project_id=${projectId}`;
      fetchMapUser(query);
    } else {
      setUpdateMapUserTableData([]);
    }
  }, [selectedProjectId]);



  //----------------------- Functions -----------------------//


  const handleDeleteRow = (row) => {
  if (!hasPermission(assignedPermissionsArray, 'manage_debris_type')) {
    setPermissionText('You do not have permission to delete a user');
    setPermissionPopUp(true);
  } else {
    setSelectedUser(row.key);
    setDeletePermissionModal(true);
  }
  }

  const deleteUser = () => {
    if (selectedUser) {
      const id = selectedUser;
      const payload = {
        user: id,
        project: selectedProjectId,
      }
      main_api.post(adminAPIsEndPoints.REMOVE_ASSIGNED_USER_FROM_PROJECT, payload)
      .then((response) => {
        if (response.status === 200) {
          pushNotification("User Removed Successfully!", "success");
          if(selectedProjectId){
            const query = `project_id=${selectedProjectId}`;
            fetchMapUser(query);
            setDeletePermissionModal(false);
          } else {
            setUpdateMapUserTableData([]);
          }
        }
      }).catch((error) => {
        pushNotification(error.response.data.detail, "error");
      });
    }
  }

  

 
  

  return (
    <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        {/* <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Map Users" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
        </div> */}
        <div style={{
          display: "flex", 
          flexDirection: "row", 
          justifyContent: "space-between", 
          position: "sticky", 
          top: "0px",
          right: "0px",
          width: "1525px",
          height: "40px",
          backgroundColor: "white", // Ensure the background is set to avoid content overlap
          zIndex: 1, // Optional: Ensures it stays above other content
        }}>
          <Heading text="Map Users" margin="10px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
        </div>

         <Heading text="Projects" margin="30px 0px 0px 5px" fontSize="0.87rem" fontWeight={400} color="#344054" />
            <PaginatedSelect 
              fetchData={fetchProjectOptions} 
              placeholder="Project Name" 
              options={projectsOptions} 
              value={selectedProjectId}
              allowClear={true} 
              onChange={(e) => setSelectedProjectId(e)} 
              style={{marginLeft: '0px',
                  marginBottom: '20px',
                  position: 'relative',
                  top: '12px',
                  left: '6px',
                  width: '260px',
                  height: '40px',}}
            />

            {/* <CustomFilter
              paginatedSelect={true}
              resetFilters={true}
              paginatedSelectPlaceholder={'Project Name'}
              resetFiltersText="Reset Filter"
              paginatedSelectOptions={projectsOptions}
              onPaginatedSelectChange={(e) => setSelectedProjectId(e)}
              paginatedSelectFetchData={fetchProjects}
              onResetFiltersClick={() => {
                setSelectedProjectId(null);
              }}
              paginatedSelectStyle={{
                marginLeft: '0px',
                marginBottom: '20px',
                position: 'relative',
                top: '12px',
                left: '6px',
                width: '260px',
                height: '40px',
              }}
              resetFiltersStyle={{
                cursor: 'pointer',
                color: '#EE3E41',
                marginLeft: '15px',
                marginBottom: '20px',
                position: 'relative',
                top: '20px',
                left: '6px',
                width: '260px',
                height: '40px',
              }}
            /> */}

            <UpdateMapUser 
              positionOptions={positionOptions} 
              setRoleSelected={setRoleSelected} 
              setPositionSelected={setPositionSelected}
              fetchPosition={fetchPosition}
              availableUserData={availableUserData}
              selectedProjectId={selectedProjectId}
              setPermissionText={setPermissionText}
              setPermissionPopUp={setPermissionPopUp}
            />

        <AntdesignTablePagination 
          columns={updateMapUserColumns({handleDeleteRow, assignedPermissionsArray})} 
          data={updateMapUserTableData}
          totalCount={countUpdateMapUser }
          loadPaginatedData={fetchMapUser} 
          allowRowSelection={false}
          tableHeight={450}
          tableWidth={1200} 
        />

        {
          deletePermissionModal &&  
          <DeletePermissionModal
            isModalOpen={deletePermissionModal} 
            title='Remove User'
            description='Are you sure you want to remove this user from the project? This action cannot be undone.'
            deleteText='Remove'
            onDelete={deleteUser} 
            setModalOpen={setDeletePermissionModal} 
          />
        }

        {permissionPopUp && (
          <PermissionModal 
            text={permissionText}
            onclose={() => setPermissionPopUp(false)}
            openModal={permissionPopUp}
          />
        )}

    </CustomCard>
  </div>
  )
}



const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

// const CustomCard = styled(Card)`
//   width: calc(100vw - 40px);
//   max-width: 1570px;
//   height: calc(100vh - 40px);
//   max-height: 690px;
//   margin-top: 40px;
//   margin-left: 40px;
//   background-color: white;
  
//   @media (max-width: 768px) {
//     width: calc(100vw - 20px);
//     height: calc(100vh - 20px);
//     margin: 10px;
//   }
// `;

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1570px;
  height: calc(100vh - 40px);
  max-height: 770px;
  margin-top: 40px;
  margin-left: 40px;
  background-color: white;
  overflow-y: auto; /* Ensure the scrollbar is visible when content overflows */
  
  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    /* Adjusting height by adding padding */
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;



const UpdateUserMapCard = styled(Card)`
    width: calc(100vw - 40px);
    max-width: 1520px;
    height: calc(100vh - 40px);
    max-height: 520px;
    margin-top: 40px;
    margin-left: 0px;
    margin-bottom: 40px;
    background-color: #EBF0F7;
    
    @media (max-width: 768px) {
      width: calc(100vw - 20px);
      height: calc(100vh - 20px);
      margin: 10px;
    }
  `;
