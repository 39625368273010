import React, { useEffect, useRef, useState } from 'react';
import MapView from '@arcgis/core/views/MapView';
import WebMap from '@arcgis/core/WebMap';
import Map from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import SimpleMarkerSymbol from '@arcgis/core/symbols/SimpleMarkerSymbol';
import Point from '@arcgis/core/geometry/Point';  
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'; // Import GraphicsLayer
import GeoJSONLayer from '@arcgis/core/layers/GeoJSONLayer';
import PopupTemplate from '@arcgis/core/PopupTemplate';
import Track from "@arcgis/core/widgets/Track.js";
import Sketch from "@arcgis/core/widgets/Sketch.js";
import Locate from "@arcgis/core/widgets/Locate.js";
import * as locator from "@arcgis/core/rest/locator.js";
import Circle from '@arcgis/core/geometry/Circle';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import SimpleLineSymbol from '@arcgis/core/symbols/SimpleLineSymbol';
import { queryPlacesNearPoint, fetchPlace } from '@arcgis/core/rest/places';
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import esriRequest from "@arcgis/core/request";
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import Field from '@arcgis/core/layers/support/Field';
import request from '@arcgis/core/request';


export default function Maps({handleReverseGeocode}) {
  const mapRef = useRef(null);

  const [suggestions, setSuggestions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  let isDragging = false; // Flag to indicate if dragging is active
  let map = null;
  let view = null;
  const graphicsLayer = new GraphicsLayer();
  const portalUrl = "https://www.arcgis.com";
    

  useEffect(() => {

    if (mapRef.current) {
      // Create a new WebMap instance
      map = new Map({
        basemap: 'streets-navigation-vector',
        // basemap: "osm-standard"
      });

      view = new MapView({
        container: mapRef.current,
        map: map,
        center: [67.0011, 24.8607], // Longitude, Latitude for Karachi,
        zoom: 14,
        popup: {
          defaultPopupTemplateEnabled: true
        }
      });


      document.getElementById("uploadForm").addEventListener("change", (event) => {
        const fileName = event.target.value.toLowerCase();

        if (fileName.indexOf(".zip") !== -1) {//is file a zip - if not notify user
          generateFeatureCollection(fileName);
        }
        else {
          document.getElementById('upload-status').innerHTML = '<p style="color:red">Add shapefile as .zip file</p>';
        }
      });
     
      const graphicsLayer = new GraphicsLayer();

      // Add the GraphicsLayer to the map
      map.add(graphicsLayer);



      let pinGraphic; // To store the pin graphic

      // Function to add or update the pin at the given location
      function updatePin(location) {
        console.log('Location:', location);
        if (!pinGraphic) {
          const pinSymbol = {
            type: "simple-marker",
            color: "red",
            size: "10px"
            // width: "150px",
            // height: "150px"
          };
          pinGraphic = new Graphic({
            geometry: location,
            symbol: pinSymbol
          });
          graphicsLayer.add(pinGraphic);
        } else {
          pinGraphic.geometry = location;
        }
      }

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          // const location = new Point({
          //   longitude: position.coords.longitude,
          //   latitude: position.coords.latitude
          // });
          const location = new Point({
            longitude: 74.26664749999998,
            latitude: 31.467816899999995
          });
          // 31.49580504470995,74.33554497055225
          // 31.481521771852773,74.30301309417163
          // 31.467816899999995,74.26664749999998
          updatePin(location);
          view.center = location;
          view.zoom = 13;
        }, function(error) {
          console.error("Geolocation error: ", error);
        });
      } else {
        console.error("Geolocation is not supported by this browser.");
      }
    
      view.on("pointer-down", function(event) {
        console.log("Pointer down 1: ", event);
        view.hitTest(event).then(function(response) {
          console.log("Pointer down 2: ", event);
          const graphic = response.results[0]?.graphic;
          if (graphic === pinGraphic) {
            console.log("Pointer down 3: ", event);
            isDragging = true;
            view.container.style.cursor = "grabbing";

          }
        });
      });
      
      view.on("pointer-move", function(event) {
        if (isDragging) {
          console.log("Pointer move 2 ", event);
          const newLocation = view.toMap({ x: event.x, y: event.y });
          pinGraphic.geometry = newLocation;
          updatePin(newLocation);
        }
      });
      
      view.on("pointer-up", function() {
        console.log("Pointer up 1");
        if (isDragging) {
          console.log("Pointer up 2: ");
          isDragging = false;
          view.container.style.cursor = "default";

          view.navigation.mouseWheelZoomEnabled = true;
          view.navigation.browserTouchPanEnabled = true;
          view.navigation.mouseDragPanEnabled = true;
          const location = pinGraphic.geometry;
          console.log("New Latitude:", location.latitude);
          console.log("New Longitude:", location.longitude);
          handleReverseGeocode(location.latitude, location.longitude);
        }
      });

      return () => {
        if (view) {
          view.destroy();
        }
      };
    }
  }, []);


  function generateFeatureCollection (fileName) {
    let name = fileName.split(".");
    // Chrome adds c:\fakepath to the value - we need to remove it
    name = name[0].replace("c:\\fakepath\\", "");

    document.getElementById('upload-status').innerHTML = '<b>Loading </b>' + name;

    // define the input params for generate see the rest doc for details
    // https://developers.arcgis.com/rest/users-groups-and-items/generate.htm
    const params = {
      'name': name,
      'targetSR': view.spatialReference,
      'maxRecordCount': 1000,
      'enforceInputFileSizeLimit': true,
      'enforceOutputJsonSizeLimit': true
    };

    // generalize features to 10 meters for better performance
    params.generalize = true;
    params.maxAllowableOffset = 10;
    params.reducePrecision = true;
    params.numberOfDigitsAfterDecimal = 0;

    const myContent = {
      'filetype': 'shapefile',
      'publishParameters': JSON.stringify(params),
      'f': 'json',
    };

    // use the REST generate operation to generate a feature collection from the zipped shapefile
    request(portalUrl + '/sharing/rest/content/features/generate', {
      query: myContent,
      body: document.getElementById('uploadForm'),
      responseType: 'json'
    })
    .then((response) => {
        const layerName = response.data.featureCollection.layers[0].layerDefinition.name;
        document.getElementById('upload-status').innerHTML = '<b>Loaded: </b>' + layerName;
        addShapefileToMap(response.data.featureCollection);
      })
      .catch(errorHandler);
  }

  function errorHandler (error) {
    console.log('1')
    // document.getElementById('upload-status').innerHTML =
    // "<p style='color:red;max-width: 500px;'>" + error.message + "</p>";
  }

  function addShapefileToMap (featureCollection) {
    // add the shapefile to the map and zoom to the feature collection extent
    // if you want to persist the feature collection when you reload browser, you could store the
    // collection in local storage by serializing the layer using featureLayer.toJson()
    // see the 'Feature Collection in Local Storage' sample for an example of how to work with local storage
    let sourceGraphics = [];

    const layers = featureCollection.layers.map((layer) => {

      const graphics = layer.featureSet.features.map((feature) => {
        return Graphic.fromJSON(feature);
      })
      sourceGraphics = sourceGraphics.concat(graphics);
      const featureLayer = new FeatureLayer({
        objectIdField: "FID",
        source: graphics,
        fields: layer.layerDefinition.fields.map((field) => {
        return Field.fromJSON(field);
        })
      });
      return featureLayer;
      // associate the feature with the popup on click to enable highlight and zoom to
    });
    map.addMany(layers);
    view.goTo(sourceGraphics)
    .catch((error) => {
      if (error.name != "AbortError"){
        console.error(error);
      }
    });

    document.getElementById('upload-status').innerHTML = "";
  }

  function searchPlaces(searchTerms) {
    let apiKey = "AAPTxy8BH1VEsoebNVZXo8HurI5jRAgwwddVWrlQ8v0Q3UqXWfHGzcS4rd9I7KcaZTGJx3dUyV4SS7aWH2iVoQFgfhP8Dyq3IEHjZAep3UTvn24kAtOJKIzxHtuSr1R9RLJ_go3Cv12plyycGoC6h7JGsKFq_OfpqoNF6k43lOr5iFjf8-eoDNvH_8CF-hAM2YLZlY17rx1IR6eFKYC-3d2Fs2eLQtShHCrnhWhiyAx-_qU.AT1_a7qsgEXi"
    const url = `https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json&text=${searchTerms}&key=${apiKey}`;
  
    fetch(url)
      .then(response => response.json())
      .then(data => {
        // Process the search results
        console.log(data.candidates);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }

  const handleSuggestionClick = (location) => {
    if (view) {
      const point = {
        type: 'point',
        longitude: location.location.x,
        latitude: location.location.y
      };

      const symbol = {
        type: 'simple-marker',
        color: 'blue',
        size: '8px'
      };

      const graphic = new Graphic({
        geometry: point,
        symbol: symbol
      });

      const graphicsLayer = new GraphicsLayer();
      view.map.add(graphicsLayer);
      graphicsLayer.add(graphic);

      view.center = point;
      view.zoom = 14;
    }
  };


  function addShapefileToMap (featureCollection) {
    // add the shapefile to the map and zoom to the feature collection extent
    // if you want to persist the feature collection when you reload browser, you could store the
    // collection in local storage by serializing the layer using featureLayer.toJson()
    // see the 'Feature Collection in Local Storage' sample for an example of how to work with local storage
    let sourceGraphics = [];

    const layers = featureCollection.layers.map((layer) => {

      const graphics = layer.featureSet.features.map((feature) => {
        return Graphic.fromJSON(feature);
      })
      sourceGraphics = sourceGraphics.concat(graphics);
      const featureLayer = new FeatureLayer({
        objectIdField: "FID",
        source: graphics,
        fields: layer.layerDefinition.fields.map((field) => {
        return Field.fromJSON(field);
        })
      });
      return featureLayer;
      // associate the feature with the popup on click to enable highlight and zoom to
    });
    map.addMany(layers);
    view.goTo(sourceGraphics)
    .catch((error) => {
      if (error.name != "AbortError"){
        console.error(error);
      }
    });

    document.getElementById('upload-status').innerHTML = "";
  }


  return <div style={{ height: '100vh' }}>
    {/* <div ref={mapRef} style={{ height: '100%' }}></div>
      <form enctype="multipart/form-data" method="post" id="uploadForm">
            <div class="field">
              <label class="file-upload">
                <span><strong>Add File</strong></span>
                <input type="file" name="file" id="inFile" />
              </label>
            </div>
      </form>
    <span class="file-upload-status" id="upload-status"></span>
    <div id="fileInfo"> </div> */}

</div>
}
