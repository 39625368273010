


import React, { useState, useCallback, useEffect } from 'react';
import { Form, Input, Col, Row } from 'antd';
import { AimOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import { CustomModal } from '../customModal/customModal';
import Maps from '../../pages/map/maps';
import * as locator from "@arcgis/core/rest/locator.js";
import esriConfig from "@arcgis/core/config";

const LocationSelector = ({ name = "address_1" ,label = "", address = "", setAddress = () => {}, setAddressLatAndLong, form, checked, formItemStyles,style, addressLatAndLong }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [position, setPosition] = useState({ lat: null, lng: null }); // Default position
  const [debounceTimeout, setDebounceTimeout] = useState(null);

  const key = process.env.REACT_APP_ARC_GIS_API_KEY;
  esriConfig.apiKey = key // Replace with your actual API key
  const geocodeUrl = `https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer`;

  useEffect(() => {
    form.setFieldsValue({
      [name]: address,
    });
  }, [address, form]);

  // Geocode
  const handleGeocode = async (value=address) => {
    setAddress(value)

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    // Set a new timeout to trigger handleGeocode after the delay
    const timeout = setTimeout(async() => {
      try {
        const results = await locator.addressToLocations(geocodeUrl, {
          address: { SingleLine: value },
          maxLocations: 1, // Limit results to the top candidate,
          outFields: ['*']
        });
    
        if (results.length > 0) {
          const { location } = results[0];
          const { x: lng, y: lat } = location;
          setAddressLatAndLong([lat, lng]);
        } else {
          setAddressLatAndLong([null, null]);
        }
      } catch (error) {
        console.error("Error in forward geocoding:", error);
      }
    }, 500); // Adjust the delay as needed (500ms in this case)

    setDebounceTimeout(timeout);
  };

  // Reverse Geocode
  const handleReverseGeocode = async (lat, lng) => {
    try {
      const results = await locator.locationToAddress(geocodeUrl, {
        location: {
          x: lng,
          y: lat,
        },
      });
  
      if (results.address) {
        const address = results.address;
        console.log("Reverse Geocoding Result:", address);
        setAddress(address); // Assuming you have a function or state to store the address details
        setAddressLatAndLong([lat, lng]);
      } else {
        console.log("No address found for the given coordinates.");
      }
    } catch (error) {
      console.error("Error in reverse geocoding:", error);
    }
  };

  const savePosition = (lat, lng) => {
    setPosition({ lat, lng });
  }


  // Handle modal close
  const handleOk = useCallback(() => {
    // handleReverseGeocode(position.lat, position.lng);
    setModalOpen(false);
  }, []);

  return (
    <div>
      <Form.Item
        name={name}
        label={label}
        colon={false}
        style={formItemStyles}
        rules={checked ? [
          {
            required: true,
            message: "Please enter address",
          },
        ]
          :
          [
            {
              required: false,
              message: "Please enter address",
            },
          ]
        }
      >
        <Input
          placeholder="Enter Address"
          value={address}
          onChange={(e) => handleGeocode(e.target.value)}
          // onBlur={handleGeocode}
          style={style}
          suffix={<AimOutlined onClick={() => {
            // handleGeocode();
            setModalOpen(true);
          }} />}
        />
      </Form.Item>
      <CustomModal
        open={isModalOpen}
        title="Select Location"
        width="50vw"
        height="80%"
        onCancel={handleOk}
        maskClosable={false}
        footer={null}
        centered
      >
        <Row >
          <Col lg={24}>
            <MapContainer>
              <Maps addressLatAndLong={addressLatAndLong} handleReverseGeocode={handleReverseGeocode}/>
              {/* <CustomGoogleMap
                markers={markers}
                position={position}
                setPosition={(newPosition) => {
                  setPosition(newPosition);
                  setMarkers([{ lat: newPosition.lat, lng: newPosition.lng, draggable: true }]);
                }}
                handleReverseGeocode={handleReverseGeocode}
                mapHeight={"400px"}
                mapWidth={"100%"}
              /> */}
            </MapContainer>
          </Col>
        </Row>
      </CustomModal>
    </div>
  );
};

const MapContainer = styled.div`
  width: 100%;
`;

export default LocationSelector;
