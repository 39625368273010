import React from 'react'

export default function Settings() {
  return (
    <div
    className='App d-flex justify-content-center align-items-center flex-column'
    style={{
      height: "80vh" 
    }}
  >
    <h1>Welcome!!</h1>
    <h3>This is the Settings page.</h3>
  </div>
  )
}
