import React, { useEffect, useState } from 'react';
import { Card, Checkbox, Divider } from 'antd';
import styled from 'styled-components';
import HeadingComponent from '../headingComponent/heading';
import CustomButton from '../customButton/customButton';
import CustomFilter from '../customFilterWithSearchBar/customFilter';
import { status } from '../../util/dropdownData';
import { useSelector } from 'react-redux';
import usePermissions from '../hooks/usePermissions';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { pushNotification } from '../../util/notification';

export default function MenuSetup() {
  //----------------------- Custom Hooks -----------------------//

  const { permissions, setPermissions, assignedPermissions, setAssignedPermissions, fetchPermissions, fetchAssignedPermissions, updatePermissions } = usePermissions();

  //----------------------- State Management -----------------------//

  const [initialPermissions, setInitialPermissions] = useState([]);



  const [roleSelected, setRoleSelected] = useState(null);
  const [userSelected, setUserSelected] = useState(null);
  const [users, setUsers] = useState([]);
  const [addPermissions, setAddPermissions] = useState([]);
  const [removePermissions, setRemovePermissions] = useState([]);
  const [page, setPage] = useState(1);


  const rolesState = useSelector((state) => state.roles.roles);
  const profileState = useSelector((state) => state?.profileData?.profileData); 
  const currentUserId = profileState?.id;
  const roles = rolesState.map((role) => ({
    label: role.name,
    value: role.id,
  }));

    
  useEffect(() => {
    if (roleSelected) {
      // const query = `role=${roleSelected}`;
      // fetchUsers(query);
      fetchUsers();
   
    }
  }, [roleSelected]);

  useEffect(() => {
    const id = userSelected;
    if (id) {
      fetchPermissions(id); 
    }
  }, [userSelected]);

  useEffect(() => {  
    const allPermissionIds = permissions?.all_permissions?.flatMap((permissionGroup) =>
      permissionGroup.permissions
        .filter((subPermission) =>
          permissions?.assigned_permissions?.some(
            (assignedPermission) => assignedPermission.id === subPermission.permission_id
          )
        )
        .map((subPermission) => subPermission.permission_id)
    ) || [];
    
    setAddPermissions(allPermissionIds);
    setInitialPermissions(allPermissionIds);
  }, [permissions]);

 

  // const fetchUsers = async (query = '', page = 1) => {
  //   // Logic for fetching users
  //   if (query === null) return;
  //   main_api.get(`${adminAPIsEndPoints.LIST_USER(query)}&page=${page}`)
  //   .then((response) => {
  //     const result = response.data.results;
  //     if (result) {
  //       const options = result.filter(item => item.name !== null).map((item) => ({
  //         label: item.name,
  //         value: item.id,
  //       }));
  //       setUsers(options);
  //     } else {
  //       setUsers([]);
  //       pushNotification('No data found!', 'error');
  //     }
  //   })
  //   .catch((error) => {
  //     pushNotification(error.response.data.detail, 'error');
  //   });
  // };

  const fetchUsers = async ( page = 1) => {
    // Logic for fetching users
    if (roleSelected) {
      const query = `role=${roleSelected}&is_active=true`;
      main_api.get(`${adminAPIsEndPoints.LIST_USER(query)}&page=${page}`)
      .then((response) => {
        const result = response.data.results;
        if(response.data.next){
          const urlObj = new URL(response.data.next);
  
          // Get the search parameters
          const params = new URLSearchParams(urlObj.search);
  
            // Extract the page number
          const pageNumber = params.get('page');
  
          setPage(pageNumber);
  
        } else{
          setPage(null);
        }
        if (result) {
          let options = [
            ...users,
            ...result.filter(item => item.name !== null).map((item) => ({
              label: item.name,
              value: item.id,
            })),
          ];
          options = options.filter((item, index, self) =>
            index === self.findIndex((t) => t.value === item.value)
          );
          setUsers(options);
        } else {
          setUsers([]);
          pushNotification('No data found!', 'error');
        }
      })
      .catch((error) => {
        pushNotification(error.response.data.detail, 'error');
      });
    } else {
      setUsers([]);
    }
  };


  const handleResetPermissions = () => {
    
    setAddPermissions(initialPermissions);
    setRemovePermissions([]);
    
  };
  

  const handleSubmitPermissions = () => {
    // Logic for submitting permissions
    const permissionsPayload = {
      add_permissions: addPermissions,
      remove_permissions: removePermissions,
    };
    const id = userSelected;
    updatePermissions(id, permissionsPayload);
  };

  return (
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Heading
          text="Manage User Menu Setup"
          margin="0px 0px 0px 5px"
          fontSize="1.3rem"
          color="#3B3B3B"
        />
      </div>
      <CustomFilter
        filter1={true}
        paginatedSelect={true}
        resetFilters={true}
        filter1Placeholder={'User Role'}
        paginatedSelectPlaceholder={'User Name'}
        resetFiltersText="Reset Filter"
        filter1Options={roles}
        paginatedSelectOptions={users}
        pageNumber={page}
        onFilter1Change={(e) => {
          setRoleSelected(e);
          setUserSelected(null);
        }}
        onPaginatedSelectChange={(e) => setUserSelected(e)}
        paginatedSelectFetchData={fetchUsers}
        onResetFiltersClick={() => {
          setRoleSelected(null);
          setUserSelected(null);
          setPermissions(null);
        }}
        filter1Style={{
          marginLeft: '0px',
          marginBottom: '20px',
          position: 'relative',
          top: '12px',
          left: '6px',
          width: '260px',
          height: '40px',
        }}
        paginatedSelectStyle={{
          marginLeft: '20px',
          marginBottom: '20px',
          position: 'relative',
          top: '12px',
          left: '6px',
          width: '260px',
          height: '40px',
        }}
        resetFiltersStyle={{
          cursor: 'pointer',
          color: '#EE3E41',
          marginLeft: '15px',
          marginBottom: '20px',
          position: 'relative',
          top: '20px',
          left: '6px',
          width: '260px',
          height: '40px',
        }}
      />
     {permissions ?
      <PermissionsGridContainer>
        {permissions?.all_permissions?.map((permission, index) => {
          const allSubPermissionsAssigned = permission?.permissions.every((subPermission) =>
            addPermissions.includes(subPermission.permission_id)
          );
          return (
            <PermissionGroup key={index}>
               <StickyHeading>

                <div style={{ display: 'flex', flexDirection: 'row', marginRight: '20px' }}>
                  <Checkbox 
                    checked={allSubPermissionsAssigned} 
                    onChange={(e) => {
                      const ids = permission?.permissions.map((subPermission) => subPermission.permission_id);
                      if (e.target.checked) {
                        setAddPermissions((prev) => [...new Set([...prev, ...ids])]);
                        setRemovePermissions((prev) => prev.filter((id) => !ids.includes(id)));
                      } else {
                        setRemovePermissions((prev) => [...new Set([...prev, ...ids])]);
                        setAddPermissions((prev) => prev.filter((id) => !ids.includes(id)));
                      }
                    }}
                  />
                  <Heading
                    text={permission?.group_name}
                    margin="2px 0px 0px 5px"
                    fontFamily="Manrope"
                    fontSize="1.125rem"
                    fontWeight={600}
                    color="#111827"
                  />
                </div>
               </StickyHeading>
              {permission?.permissions?.map((subPermission, subIndex) => (
                <div
                  key={subIndex}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    marginLeft: '20px',
                    marginTop: '15px',
                    marginBottom: '15px',
                  }}
                >
                  <Checkbox
                    checked={
                      addPermissions.includes(subPermission.permission_id)
                    }

                    onChange={(e) => {
                      const id = subPermission.permission_id;
                      if (e.target.checked) {
                        setAddPermissions((prev) => [...new Set([...prev, id])]);
                        setRemovePermissions((prev) => prev.filter((removeId) => removeId !== id));
                      } else {
                        setRemovePermissions((prev) => [...new Set([...prev, id])]);
                        setAddPermissions((prev) => prev.filter((addId) => addId !== id));
                      }
                    }}
                  />
                  <Heading
                    text={subPermission?.name}
                    margin="2px 0px 0px 5px"
                    fontFamily="Ubuntu"
                    fontSize="0.875rem"
                    fontWeight={400}
                    color="#344054"
                  />
                </div>
              ))}
            </PermissionGroup>
          );
        })}
      </PermissionsGridContainer>
      :
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems:"center", minHeight:"500px" }}>
        <Heading
          text="No permissions found, Please select a user to view permissions"
          margin="0px 0px 0px 5px"
          fontSize="1.3rem"
          color="grey"
        />
      </div>
      }

      <Divider
        style={{
          width: '99.9%',
          position: 'absolute',
          top: '600px',
          right: '0px',
          borderTop: '1px solid #DEE2E6',
        }}
      />
      <div
        className="d-flex justify-content-center"
        style={{ width: '20%', position: 'absolute', top: '650px', left: '460px' }}
      >
        <CustomButton
          btnText={'Reset'}
          color={'#EE3E41'}
          width={'150px'}
          margin="0px 5px"
          noBackground
          hideIcon={true}
          onClick={handleResetPermissions}
        />
        <CustomButton
          btnText={'Save Changes'}
          color={'white'}
          width={'150px'}
          type="button"
          hideIcon={true}
          onClick={handleSubmitPermissions}
        />
      </div>
    </CustomCard>
  );
}

// Styled Components
const PermissionsGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  max-height: 500px;
  overflow-y: auto;
  padding: 10px;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const PermissionGroup = styled.div`
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;

  /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const StickyHeading = styled.div`
  position: sticky;
  top: 0;
  background-color: white; /* Match the background color of the container */
  z-index: 1;
  padding-right: 10px;
`;


const Heading = ({
  text = '',
  margin,
  fontFamily,
  fontSize = '0.75rem',
  fontWeight,
  color = '#3B3B3B',
}) => {
  return (
    <HeadingComponent
      text={text}
      fontFamily={fontFamily}
      fontSize={fontSize}
      color={color}
      fontWeight={fontWeight ? fontWeight : 700}
      margin={margin}
    />
  );
};


const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1270px;
  height: calc(100vh - 40px);
  max-height: 720px;
  margin: 20px;
  background-color: white;

  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;