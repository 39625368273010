import { createSlice } from "@reduxjs/toolkit";
import { adminAPIsEndPoints } from "../../constants/apiEndPoints";
import { main_api } from "../../api/axiosHelper";

//----------------------- Initial state -----------------------//


const initialState = {
    isLoading: false,
    error: "",
    permissionsData: [],
};

//----------------------- Slice -----------------------//

const slice = createSlice({
    name: "permissionsData",
    initialState,
    reducers: {
        getPermissionsDataStart(state) {
            state.isLoading = true;
            state.error = "";
            state.permissionsData = [];
        },
        getPermissionsDataSuccess(state, action) {
            state.isLoading = false;
            state.permissionsData = action.payload;
        },
        getPermissionsDataFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
    },
});

//----------------------- Actions -----------------------//

export const { getPermissionsDataStart, getPermissionsDataSuccess, getPermissionsDataFailure } = slice.actions;


//----------------------- Thunk -----------------------//

export function getPermissionsData() {
    return async (dispatch) => {
        try {
            dispatch(getPermissionsDataStart());
            const response = await main_api.get(adminAPIsEndPoints.LIST_ASSIGNED_PERMISSION);
            dispatch(getPermissionsDataSuccess(response?.data?.assigned_permissions));
        } catch (error) {
            dispatch(getPermissionsDataFailure(error.message));
        }
    };
};


// Reducer
export default slice.reducer;