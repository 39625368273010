
import React, { useState } from 'react';
import { ReactComponent as UploadIcon } from '../../assets/rawSvg/ImageSquare.svg';
import { Button, Upload } from 'antd';
import CustomButton from '../customButton/customButton';
import { pushNotification } from '../../util/notification';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';

export default function CustomUploader({ file, setFile, uploadedFiles, setUploadedFiles, directory, attachmentType= null, requiredPhotoCount = {} }) {

  const { Dragger } = Upload;

  const allowedTypes = [
    "pdf",
    "png",
    "jpg",
    "jpeg",
    "gif"
  ];

  const removeFile = (index) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index);
    setUploadedFiles(updatedFiles);
  };

  const isValidFileDirectory = (file) => {
    const allowedDirectories = [
      "user", 
      "project", 
      "ticket", 
      "disposalsite",
      "truck"
    ];


    if (!allowedDirectories.includes(directory)) {
      pushNotification("File should be uploaded to a valid directory", "error");
      return false;
    }

    return true;
  }



  const isValidFileType = (file) => {
  
    const allowedTypes = [
      "application/pdf",
      "image/png",
      "image/jpg",
      "image/jpeg",
      "image/gif"
    ];
    
    const { type: fileType, width, height } = file;

    if (!allowedTypes.includes(fileType)) {
      pushNotification("File should be of type PDF, PNG, JPG, JPEG, or GIF","info");
      return false;
    }

    // Check for image size
    if (fileType.startsWith("image/") && (width > 800 || height > 400)) {
      pushNotification("Image dimensions should be a maximum of 800 x 400px", "info");
      return false;
    }

    return true;
  }

  const uploadToS3 = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("directory", directory);



    main_api.post(adminAPIsEndPoints.UPLOAD_FILE, formData)
      .then((response) => {
        if (response.status === 201) {
          
          const result = response.data.result;
          const fileName = result.key.split("/").pop().split(".")[0];
          
          
          setFile({ url: result.url, name: fileName, type: result.type });
         
          setUploadedFiles([...uploadedFiles, { key: result.key, url: result.url, name: fileName, type: result.type, attachment_type: attachmentType }]);
          pushNotification("File uploaded successfully", "success");
        } else {
          pushNotification("Failed to upload file", "error");
        }
      })
      .catch((error) => {
        pushNotification(error?.data?.detail, "error");
      });
  };

  const props = {
    name: "file",
    showUploadList: false,
    beforeUpload(file) {
      if (!isValidFileType(file) || !isValidFileDirectory(file)) {
        return false;
      }
      uploadToS3(file);
      return false; // Returning false prevents default behavior (uploading the file)
    },
    onDrop(e) {
    }
  };

  return (
    <Dragger {...props} style={{ width: "570px" }}>
    <span style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
      {uploadedFiles && uploadedFiles.length > 0 ? (
        uploadedFiles.map((file, index) => (
          <div key={index} style={{ position: 'relative', margin: '5px' }}>
            {allowedTypes.includes(file.type) && file.type !== "gif" ? (
              <img 
                src={file.url} 
                alt="Uploaded File" 
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "8px", 
                  objectFit: "cover"
                }}
              />
            ) : (
              <p 
                style={{
                  width: "60px",
                  height: "60px",
                  background: "#E5EDFF",
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.75rem",
                  padding: "10px",
                  textAlign: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap"
                }}
              >
                {file.name}
              </p>
            )}
            <span 
              onClick={() => removeFile(index)} 
              style={{
                position: 'absolute', 
                top: '-10px', 
                right: '-10px',
                width: '20px',
                height: '20px',
                backgroundColor: '#fff',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)'
              }}
            >
              ×
            </span>
          </div>
        ))
      ) : (
        <p style={{
          width: "60px",
          height: "60px",
          background: "#E5EDFF",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}>
          <UploadIcon />
        </p>
      )}
      <span style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", marginLeft: "20px" }}>
        <p style={{ color: "#667085", fontSize: "1rem" }}>
          <span style={{ marginRight: "10px", color: "#3669AE", fontSize: "1rem" }}>
            Click to upload 
          </span>
          or drag and drop
        </p>
        <p style={{ color: "#667085", fontSize: "0.8rem" }}>
          PDF, PNG, JPG, JPEG or GIF max. 800 x 400px
        </p>
        <span>
          {/* <Button
            style={{ width: "100px", height: "40px", background: "#3669AE", color: "#fff", borderRadius: "8px" }}
            onClick={uploadToS3}
            type={"button"}
          >
            Upload
          </Button> */}

          <Button
                style={{ width: "100px", height: "40px", background: "#3669AE", color: "#fff", borderRadius: "8px" }}
                onClick={() => document.querySelector('.ant-upload input[type="file"]').click()}
                type="button"
              >
                Upload
          </Button>
          {/* <CustomButton btnText={"Upload"} type color={"white"} background={"#3669AE"} hideIcon={true} /> */}
        </span>
      </span>
    </span>
  </Dragger>
  )
}
