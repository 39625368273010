import { useNavigate } from "react-router-dom";
import PermissionModal from "../components/modals/permission/permissionModal";


function NoPermissionPage() {
  const navigate = useNavigate();
  const permissionPopUp = true;
  return (
    <>
      <PermissionModal 
        text={"You do not have permission to access this page"}
        onclose={() => {
          navigate("/dashboard");
        }}
        openModal={permissionPopUp}
      />
    </>
  );
}

export default NoPermissionPage;
