import React, { useState } from 'react'
import { Button, Divider, Form, InputNumber, Modal, Select } from "antd";
import CustomButton from "../../customButton/customButton";
import HeadingComponent from "../../headingComponent/heading";
import { CustomModal } from '../../customModal/customModal';
import { truckTypeOptions } from '../../../util/dropdownData';
import styled from 'styled-components';
export default function TruckCapacity({
    isModalOpen,
    setModalOpen,
    title,
    setTruckCapacity,
    }) {
    
        const [truckType, setTruckType] = useState("simple");


        const [form] = Form.useForm(); 

        const handleCalculateTruckCapacity = (values) => {
            const { truckType, length, width, height, length2, width2, height2 } = values;
            let truckCapacity = 0;
            if (truckType === "simple") {
                truckCapacity = length * width * height;
            } else {
                truckCapacity = (length * width * height) + (length2 * width2 * height2);
            }
            
            setTruckCapacity(truckCapacity);
            setModalOpen(false);
        };

    

    return (
        <CustomModal  
            open={isModalOpen}
            centered={true}
            title={title}
            width="630px"
            height="480px"
            onCancel={() => {
                form.resetFields();
                setModalOpen(false);
            }}
            
            footer={null}
            maskClosable={false}
            // isScrollable={true}
        >
        <Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>

        <Form
            form={form}
            layout="vertical"
            name="calculateTruckCapacity"
            onFinish={handleCalculateTruckCapacity}
        >
            <FormWrapper>



            <FormItem
                label="Truck Type"
                name="truckType"
                rules={[{ required: true, message: "Please select truck type!" }]}
            >
                <Select
                    placeholder="Select Truck Type"
                    value={truckType}
                    onChange={(value) => setTruckType(value)}
                    options={truckTypeOptions}
                    style={{ width: "594px"}}
                />
                
            </FormItem>
            <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
                
            <FormItem
                label='Length'
                name='length'
                rules={[{ required: true, message: "Please enter length!" }]}
            >
                <InputNumber placeholder='Enter Length' style={{ width: "100%" }} />
            </FormItem>

            <FormItem
                label='Width'
                name='width'
                rules={[{ required: true, message: "Please enter width!" }]}
                style={{marginLeft:"5px"}}    
            >
                <InputNumber placeholder='Enter Width' style={{ width: "100%" }} />
            </FormItem>

            <FormItem
                label='Height'
                name='height'
                rules={[{ required: true, message: "Please enter height!" }]}
                style={{marginLeft:"5px"}}    

            >
                <InputNumber placeholder='Enter Height' style={{ width: "100%" }} />
            </FormItem>
            </div>


            {
                truckType === "u-shaped" && (
                    <div style={{display:"flex", flexDirection:"row", marginRight:"8px"}}>
                    
                    <FormItem
                        label='Length'
                        name='length2'
                        rules={[{ required: true, message: "Please enter length!" }]}
                    >
                        <InputNumber placeholder='Enter Length' style={{ width: "100%" }} />
                    </FormItem>

                    <FormItem
                        label='Width'
                        name='width2'
                        rules={[{ required: true, message: "Please enter width!" }]}
                        style={{marginLeft:"5px"}}    

                    >
                        <InputNumber placeholder='Enter Width' style={{ width: "100%" }} />
                    </FormItem>

                    <FormItem
                        label='Height'
                        name='height2'
                        rules={[{ required: true, message: "Please enter height!" }]}
                        style={{marginLeft:"5px"}}    

                    >
                        <InputNumber placeholder='Enter Height' style={{ width: "100%" }} />
                    </FormItem>
                    </div>

                )
            }
            </FormWrapper>

            <Divider/>

            <SaveContainer className="d-flex justify-content-end">
                <CustomButton
                    btnText={"Cancel"}
                    margin="0px 5px"
                    noBackground
                    hideIcon={true}
                    onClick={() => setModalOpen(false)}
                />
                <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
            </SaveContainer>
        </Form>

        </CustomModal>
       
    );
}


const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B", position, top, bottom, right, left }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} position={position} top={top} bottom={bottom} right={right} left={left} />;
};

const FormItem = styled(Form.Item)`
  width: 100%;
`;

const FormWrapper = styled.div`
  height: 280px;
  width:  600px;
  overflow: auto;

 /* Custom scrollbar styling */
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #3669AE;
    border-radius: 2px;
    min-height: 30px; /* Ensure the thumb isn't too small */
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`;

const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
`;
