import React, { useEffect, useState } from 'react';
import { Table, Row, Col, Card, Input, Divider, DatePicker } from 'antd';
import styled from 'styled-components';
import dayjs from 'dayjs';
import SearchInput from '../../components/searchInput/SearchInput';
import HeadingComponent from '../../components/headingComponent/heading';
import { AntdesignTable } from '../../components/antDesignTable/AntdesignTable';
import { ReactComponent as NotesBadge } from "../../assets/rawSvg/notesBadge.svg";
import { ReactComponent as TicketsBadge } from "../../assets/rawSvg/ticketBadge.svg";
import { ReactComponent as UsersBadge } from "../../assets/rawSvg/usersBadge.svg";
import moment from 'moment';
import { dashboardProjectsColumns, manageProjectsColumns } from '../../util/antdTableColumns';
import { useSelector } from 'react-redux';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import { pushNotification } from '../../util/notification';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { main_api } from '../../api/axiosHelper';

const { RangePicker } = DatePicker;
const { Search } = Input;


const dummyProjectsData = [
  {
    key: '1',
    poNumber: 'PO12345',
    projectName: 'Road Clearing Phase 1',
    eventName: 'Hurricane Relief 2024',
    clientName: 'City of Miami',
    city: 'Miami',
    status: 'Completed',
  },
  {
    key: '2',
    poNumber: 'PO12346',
    projectName: 'Debris Removal',
    eventName: 'Hurricane Relief 2024',
    clientName: 'City of Houston',
    city: 'Houston',
    status: 'Cancelled',
  },
  {
    key: '3',
    poNumber: 'PO12347',
    projectName: 'Road Repair',
    eventName: 'Tornado Response 2024',
    clientName: 'City of Dallas',
    city: 'Dallas',
    status: 'Scheduled',
  },
  {
    key: '4',
    poNumber: 'PO12348',
    projectName: 'Bridge Inspection',
    eventName: 'Flood Recovery 2024',
    clientName: 'City of New Orleans',
    city: 'New Orleans',
    status: 'Unassigned',
  },
  {
    key: '5',
    poNumber: 'PO12349',
    projectName: 'Utility Restoration',
    eventName: 'Earthquake Response 2024',
    clientName: 'City of Los Angeles',
    city: 'Los Angeles',
    status: 'Completed',
  },
];


export default function Dashboard() {
  const [totalActiveProjects, setTotalActiveProjects] = useState(280);
  const [totalTickets, setTotalTickets] = useState(1280);
  const [totalUsers, setTotalUsers] = useState(586);
  const [searchText, setSearchText] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [projectsDataForTable, setProjectsDataForTable] = useState({});
  
  const [searchedValue, setSearchedValue] = useState('');
  
  const [permissionText, setPermissionText] = useState('');
  const [permissionPopUp, setPermissionPopUp] = useState(false);

  
  const today = dayjs();
  const startDate = today.clone().subtract(5, 'days');
  const endDate = today.clone().add(5, 'days');
  const [dateRange, setDateRange] = useState([startDate, endDate]);
  const profileState = useSelector((state) => state?.profileData?.profileData);
  


   //----------------------- Filter -----------------------//

   useEffect(() => {
    let query = `search=${searchedValue}`;
   
    fetchProjects(query);
  }, [searchedValue]);



  const defaultUsername = 'Guest';

  localStorage.setItem('username', `${profileState?.name ?? defaultUsername}`);
  const currentUser = localStorage.getItem('username');

  const greetings = `Welcome ${currentUser}`;
  useEffect(() => {
    fetchService();
  }, []);



  useEffect(() => {
    filterDataByDateRange();
  }, [data, dateRange]);

  const fetchService = async () => {
    try {
     
      setData(dummyProjectsData);

    } catch (error) {
      console.error('Error fetching service data:', error);
    }
  };

  const disabledDate = (current) => {
    return current && current > endDate.endOf('day');
  };

  const filterDataByDateRange = () => {
    const [start, end] = dateRange;
    const filteredDataByDate = data.filter(item => {
      const itemDate = dayjs(item.date);
      return itemDate.isAfter(start) && itemDate.isBefore(end);
    });

    setFilteredData(filteredDataByDate);
    // const activeProjects = filteredDataByDate.filter(item => item.status === '');
    // const totalTickets = filteredDataByDate.filter(item => item.status === "");
    // const totalUsers = filteredDataByDate.filter(item => item.status === '');
    setFilteredProjects(filteredDataByDate);
    
    
    // Counts
    setTotalActiveProjects(filteredDataByDate.length);
    
  };

  
  
  const onDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleSearch = (value) => {
    const filteredData = data.filter(project => {
      const matchedProjects = value ? project.projectName.toLowerCase().includes(value.toLowerCase()) : true;
      return matchedProjects;
    });
    setFilteredData(filteredData);
  };


//--------------------- Fetch Stats ---------------------//

const fetchStats = async () => {
  main_api.get(adminAPIsEndPoints.DASHBOARD_STATS)
  .then((response) => {
    const result = response.data;
    if (result) {
      setTotalActiveProjects(result?.active_project);
      setTotalTickets(result?.total_tickets);
      setTotalUsers(result?.no_of_users);
    }
  }).catch((error) => {
    pushNotification(error?.response?.data?.detail, "error");
  });
};


//--------------------- Fetch Projects ---------------------//

const fetchProjects = async (query = '', page = 1) => {
  main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
  .then((response) => {
  const result = response.data.results;
  const transformedData = result.map(item => ({
      ...item,
      key: item?.id,
      po_number: item?.po_number || '---',
      name: item?.name || '---',
      event_name: item?.event?.name || '---',
      client_name: item?.client?.name || '---',
      prime_contractor: item?.contractor?.name || '---',
      sub_activity: item?.sub_activity || '---',
      project_identfication_number: item?.project_identfication_number || '---',
      city: item?.city || '---',
      state: item?.state || '---',
      description: item?.description || '---', 
      is_active: item?.is_active || '---',
      created_at: item?.created_at || '---',
      updated_at: item?.updated_at || '---',
    
  }));
  
  setProjectsDataForTable({count: response.data.count, data: transformedData});

  }).catch((error) => {
      
  pushNotification(error?.response?.data?.detail, "error");
  });
};


//------------------ Effects ---------------------//

useEffect(() => {
  fetchStats();
  fetchProjects();
}, []);






  return (
    <div style={{ marginTop: '20px' ,marginLeft:"20px"}}>
      <div style={{ marginBlock: "8px" }}>
        <Heading text={greetings} fontSize='1.4rem'/>
        <Heading text="Here's what happening" color='lightgrey' fontSize='1.1rem'/>
      </div>
    <div style={{ marginBlock: "10px" }}>
     
      <Row gutter={16} style={{ marginBottom: '16px', marginTop: '20px' }}>
        <Col span={6}>
          <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            <NotesBadge/>
            <span style={{display: 'flex', flexDirection:"row", justifyContent: 'space-between', marginTop:"8px"}}>
            <Heading text='Active Projects' color='#667085' fontSize='14px'/>
            <Heading text={totalActiveProjects} fontSize='1.45rem'/>
            </span>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            <TicketsBadge/>
            <span style={{display: 'flex', flexDirection:"row", justifyContent: 'space-between', marginTop:"8px"}}>
            <Heading text='Total Tickets' color='#667085' fontSize='14px'/>
            <Heading text={totalTickets} fontSize='1.45rem'/>
            </span>
          </Card>
        </Col>
        <Col span={6}>
          <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
            <UsersBadge/>
            <span style={{display: 'flex', flexDirection:"row", justifyContent: 'space-between', marginTop:"8px"}}>
            <Heading text='No of Users' color='#667085' fontSize='14px'/>
            <Heading text={totalUsers} fontSize='1.45rem'/>
            </span>
          </Card>
        </Col>
      </Row>
    </div>
    <Card style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', padding:"2px" }}>
    <div style={{ marginBlock: "8px" }}>
      <Heading text='Projects' fontSize='1.2rem'/>
    </div>
      <div style={{ marginBottom: '16px' }}>
        <div className="d-flex align-items-center">
          <SearchInputWrapper>
            <SearchInput onBlur={(e) => setSearchedValue(e)} onKeyDown={(e) => setSearchedValue(e)} placeholder="Type to search..." />
          </SearchInputWrapper>
        </div>
      </div>
      <div style={{ marginBottom: '22px', position: 'relative', top: 'auto', bottom: 0, padding:"10px" }}>
        <AntdesignTablePagination 
          columns={manageProjectsColumns({handleViewDetails:null, handleMapUser:null})} 
          data={projectsDataForTable.data}
          totalCount={projectsDataForTable.count}
          loadPaginatedData={fetchProjects} 
          allowRowSelection={false}
          tableHeight={350}
          tableWidth={1200} 
        />
      </div>
    </Card>
  </div>
  );
}

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const SearchInputWrapper = styled.div`
  width: 25%;
  margin-left: 10px;
`;
