import React, { useEffect, useState } from 'react'
import { Card, Select } from 'antd'
import styled from 'styled-components';
import HeadingComponent from '../../components/headingComponent/heading';
import CustomButton from '../../components/customButton/customButton';
import { disposalSiteManagementColumns, eventManagementColumns } from '../../util/antdTableColumns';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import { pushNotification } from '../../util/notification';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { status } from '../../util/dropdownData';
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import DeletePermissionModal from '../../components/modals/permission/deletePermissionModal';
import UpdateEvent from '../../components/modals/eventManagement/updateEvent';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { hasPermission } from '../../util/permissionChecker';
import usePermissions from '../../components/hooks/usePermissions';
import useProjects from '../../components/hooks/useProjects';
import UpdateDisposalSite from '../../components/modals/disposalSiteManagement/updateDisposalSite';
import dayjs from 'dayjs';
import SearchInput from '../../components/searchInput/SearchInput';
import PaginatedSelect from '../../components/customSelect/paginatedSelect';

export default function DisposalSite() {


  //----------------------- Custom Hooks -----------------------//

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  

//---------------------- State for Data ----------------------//

const [disposalSiteDataForTable, setDisposalSiteDataForTable] = useState({});
const [projectOptions, setProjectOptions] = useState([]);
const [currentPage, setCurrentPage] = useState(1);

const [searchedValue, setSearchedValue] = useState('');
const [projectSelected, setProjectSelected] = useState(null);
const [statusSelected, setStatusSelected] = useState(null);


// const [attachmentType, setAttachmentType] = useState(null);
const [file, setFile] = useState(null);
const [uploadedFiles, setUploadedFiles] = useState([]);

const [editDisposalSiteValues, setEditDisposalSiteValues] = useState(null);

//---------------------- State for Modals ----------------------//

const [updateDisposalSiteModal, setUpdateDisposalSiteModal] = useState(false);
const [deleteDisposalSiteModal, setDeleteDisposalSiteModal] = useState(false);

const [permissionText, setPermissionText] = useState('');
const [permissionPopUp, setPermissionPopUp] = useState(false);
  


  //----------------------- Fetch Data  -------------------------//

  const fetchData = async (
    query = '', 
    page = 1, 
 ) => {
    main_api.get(`${adminAPIsEndPoints.LIST_DISPOSAL_SITES(query)}&page=${page}`)
    .then((response) => {
    const result = response.data.results;
    
    const transformedDisposalSiteData = result.map(item => ({
        key: item?.id, 
        name: item?.name.charAt(0).toUpperCase() + item?.name.slice(1) || '---',
        address: item?.address || '---',
        type: item?.site_type.charAt(0).toUpperCase() + item?.site_type.slice(1) || '---',
        is_active: item?.is_active,
        ...item, 
      }));
    

    setDisposalSiteDataForTable({count: response.data.count, data:  transformedDisposalSiteData });

    // const options = result?.map((item) => {
    //     item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
    //     item.value = item.id;
    //     return item;
    // });

    }).catch((error) => {
        
    pushNotification(error?.response?.data?.detail, "error");
    });
};


  //------------------ Functions for Update & Delete Disposal Site Modal ---------------------//
  
  const handleAddRow = () => {
    if (!hasPermission(assignedPermissionsArray, 'add_disposalsite')) {
      setPermissionText("You do not have permission to add a disposal site");
      setPermissionPopUp(true)
    } else {
      setEditDisposalSiteValues(null);
      setUpdateDisposalSiteModal(true);
    }
  };


  const handleEditRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_disposalsite')) {
      setPermissionText("You do not have permission to edit a disposal site");
      setPermissionPopUp(true)
    } else if (values) {
          setEditDisposalSiteValues(values);
          setUpdateDisposalSiteModal(true);
 
    }
  };

  const handleDeleteRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_disposalsite')) {
      setPermissionText("You do not have permission to delete a disposal site");
      setPermissionPopUp(true)
    }else if (values) {
        setEditDisposalSiteValues(values);
        setUpdateDisposalSiteModal(true);
    }
  };



 //----------------------------- Add and Edit Disposal Site Functions -----------------------------//

 const handleEditDisposalSite = async (values) => {
  
  const id = editDisposalSiteValues?.key;
  values = {
    ...values,
    date_opened: values.date_opened ? dayjs(values.date_opened).format('YYYY-MM-DD') : null,
    date_closed: values.date_closed ? dayjs(values.date_closed).format('YYYY-MM-DD') : null,
    permit_expire_date: values.permit_expire_date ? dayjs(values.permit_expire_date).format('YYYY-MM-DD') : null,
   }
  main_api.put(adminAPIsEndPoints.UPDATE_DISPOSAL_SITE(id), values)
  .then((response) => {
      if (response.status === 200) {
          pushNotification("Disposal Site updated successfully", "success");
          setUploadedFiles([]);
          const query = '';
          fetchData(query, currentPage);
          setUpdateDisposalSiteModal(false);
      }
  }).catch((error) => {
      setUploadedFiles([]);
      pushNotification(error.response.data.detail, "error");
  });
}

const handleAddDisposalSite = async (values) => {
  
  values = {
    ...values,
    date_opened: values.date_opened ? dayjs(values.date_opened).format('YYYY-MM-DD') : null,
    date_closed: values.date_closed ? dayjs(values.date_closed).format('YYYY-MM-DD') : null,
    permit_expire_date: values.permit_expire_date ? dayjs(values.permit_expire_date).format('YYYY-MM-DD') : null,
    attachments: uploadedFiles?.map(item => {
      return { key: item.key, attachment_type: item.attachment_type, type: item.type }
    }),
  }
  

  main_api.post(adminAPIsEndPoints.ADD_DISPOSAL_SITE, values)
  .then((response) => {
      if (response.status === 201) {
          pushNotification("Disposal Site added successfully", "success");
          setUploadedFiles([]);
          fetchData();
          setUpdateDisposalSiteModal(false);
      }
  }).catch((error) => {
      setUploadedFiles([]);
      pushNotification(error.response.data.detail, "error");
  });
}

const handleDeleteDisposalSite = async () => {
  const id = editDisposalSiteValues?.key;
  main_api.delete(adminAPIsEndPoints.DELETE_DISPOSAL_SITE(id))
  .then((response) => {
      if (response.status === 204) {
          pushNotification("Disposal Site deleted successfully", "success");
          const query = '';
          fetchData(query, currentPage);
          setDeleteDisposalSiteModal(false);
      }
  }).catch((error) => {
      pushNotification(error.response.data.detail, "error");
  });
}


//---------------------- Effect for Fetching Data ----------------------//

useEffect(() => {
  fetchData();
}, []);


//------------------------- Filter --------------------------//
  
   useEffect(() => {
    let query = `search=${searchedValue}`;
    
    if (statusSelected!==null) {
      query+=`&is_active=${statusSelected}`;
    }
    fetchData(query);
  }, [projectSelected, statusSelected, searchedValue]);



  return (
    <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Manage Disposal Site" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
          <CustomButton btnText={"Add Disposal Site"} color={"white"} onClick={handleAddRow} />
        </div>
        <div style={{display: "flex", flexDirection: "row"}}>
          <SearchInputWrapper>
            <SearchInput onBlur={(e) => setSearchedValue(e)} onKeyDown={(e) => setSearchedValue(e)} placeholder={"Search By Disposal Site Name..."} />
          </SearchInputWrapper>
         
          <Select
            value={statusSelected}
            placeholder={"Status"}
            options={status}
            onChange={(e) => setStatusSelected(e)}
            style={{marginLeft:"20px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}}
          />
          <div
            onClick={() => {
              setProjectSelected(null);
              setStatusSelected(null);
              setSearchedValue('');
              fetchData();
            }}
            style={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"260px", height:"40px"}}
          >
            Reset Filters
          </div>
        </div>
        
        <AntdesignTablePagination 
          columns={disposalSiteManagementColumns({handleEditRow})} 
          data={disposalSiteDataForTable.data}
          totalCount={disposalSiteDataForTable.count}
          loadPaginatedData={fetchData} 
          allowRowSelection={false}
          tableHeight={450}
          tableWidth={1200} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    </CustomCard>
    {updateDisposalSiteModal && 
      <UpdateDisposalSite 
        isModalOpen={updateDisposalSiteModal} 
        title={editDisposalSiteValues ? "Edit Disposal Site" : "Add New Disposal Site"} 
        onFinish={editDisposalSiteValues ? handleEditDisposalSite : handleAddDisposalSite  } 
        setModalOpen={setUpdateDisposalSiteModal} 
        editDisposalSiteValues={editDisposalSiteValues}
        file={file}
        setFile={setFile}
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
      />
    }
    {deleteDisposalSiteModal &&
      <DeletePermissionModal
        isModalOpen={deleteDisposalSiteModal} 
        title="Delete Disposal Site"
        description='Are you sure you want to delete this disposal site? This action cannot be undone.'
        deleteText='Delete'
        onDelete={handleDeleteDisposalSite} 
        setModalOpen={setDeleteDisposalSiteModal} 
      />
    }
    {permissionPopUp && (
      <PermissionModal 
        text={permissionText}
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    )}
  </div>
  )
}




const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1570px;
  height: calc(100vh - 40px);
  max-height: 750px;
  margin-top: 40px;
  margin-left: 40px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;


const SearchInputWrapper = styled.div`
  width: 350px;
  margin-left: 5px;
`;
