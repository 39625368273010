import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Button, DatePicker, Divider, Form, Input, InputNumber, message, Radio, Segmented, Select, Switch, TimePicker } from "antd";
import { CustomModal } from '../../customModal/customModal';
import CustomButton from '../../customButton/customButton';
import { countryCodes } from '../../../util/dropdownData';
import CustomSwitch from '../../customSwitch/customSwitch';
import HeadingComponent from '../../headingComponent/heading';
import LocationSelector from '../../customAddressInput/locationSelector';
import { useSelector } from 'react-redux';
import { getUserPositionList } from '../../../util/dataService';
import { main_api } from '../../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../../constants/apiEndPoints';
import PaginatedSelect from '../../customSelect/paginatedSelect';
import { pushNotification } from '../../../util/notification';
import TextArea from 'antd/es/input/TextArea';
import { ReactComponent as PlusIcon } from "../../../assets/rawSvg/Plus.svg";
import { ReactComponent as CrossIcon } from "../../../assets/rawSvg/Cross.svg";
import CustomUploader from '../../customUploader/customUploader';
import { fieldsMap, getFieldValidationRules } from '../../../util/fieldsMap';
import dayjs from 'dayjs';
import distanceCalculator from '../../../util/distanceCalculator';


export default function UpdateTicket({
    isModalOpen,
    title,
    setModalOpen,
    onFinish,
    updateTicketValues,
    selectedProjectForUpdateForm,
    selectedSubActivityForUpdateForm,
    selectedDebrisTypeForUpdateForm,
    setSelectedProjectForUpdateForm,
    setSelectedSubActivityForUpdateForm,
    setSelectedDebrisTypeForUpdateForm,
    loadTime,
    setLoadTime,
    disposalTime,
    setDisposalTime,
    loadDate,
    setLoadDate,
    disposalDate,
    setDisposalDate,
    padding = "20px",
}) {

  //----------------------- State Variables -----------------------//
  const rolesState = useSelector((state) => state.roles.roles);

  const [address, setAddress] = useState("");
  const [addressLatAndLong, setAddressLatAndLong] = useState([null,null]);
  
  const [disposalSiteAddressLatAndLong, setDisposalSiteAddressLatAndLong] = useState([null,null]);

    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [savedFileUrl, setSavedFileUrl] = useState(null);

    const [projectOptions, setProjectOptions] = useState([]);
    const [subActivityTypeOptions, setSubActivityTypeOptions] = useState([]);
    const [debrisTypeOptions, setDebrisTypeOptions] = useState([]);
    const [disposalSiteOptions, setDisposalSiteOptions] = useState([]);
    const [hazardTypeOptions, setHazardTypeOptions] = useState([]);
    const [fieldMonitorOptions, setFieldMonitorOptions] = useState([]);
    const [siteMonitorOptions, setSiteMonitorOptions] = useState([]);
    const [truckNumberOptions, setTruckNumberOptions] = useState([]);



    const [projectSelected, setProjectSelected] = useState(updateTicketValues?.project || null);
    const [subActivitySelected, setSubActivitySelected] = useState(updateTicketValues?.project?.sub_activity?.map(item => item.id) || null);
    const [debrisTypeSelected, setDebrisTypeSelected] = useState(updateTicketValues?.debris_type || null);
    const [disposalSiteSelected, setDisposalSiteSelected] = useState(updateTicketValues?.disposal_site || null);
    const [hazardTypeSelected, setHazardTypeSelected] = useState(updateTicketValues?.hazard_type || null);
    const [fieldMonitorSelected, setFieldMonitorSelected] = useState(null);
    const [siteMonitorSelected, setSiteMonitorSelected] = useState(null);
    const [truckNumberSelected, setTruckNumberSelected] = useState(null);


    const [selectedProjectData, setSelectedProjectData] = useState(null);
    const [selectedDebrisTypeData, setSelectedDebrisTypeData] = useState(null);
    const [selectedTruckData, setSelectedTruckData] = useState(null);
    const [showUpdateTicketForm, setShowUpdateTicketForm] = useState(false);

    const [requiredPhotoCount, setRequiredPhotoCount] = useState({});
    const [fields, setFields] = useState({});
    const [unitOptions, setUnitOptions] = useState([]);
    const [truckCapacity, setTruckCapacity] = useState(0);
    const [truckLoadVolume, setTruckLoadVolume] = useState(0);
    const [distance, setDistance] = useState(0);

    //----------------------- Form Initialization -----------------------//

    const [form] = Form.useForm();

    useEffect(() => {
        if (updateTicketValues) {
          form.setFieldsValue({
            project: updateTicketValues?.project.id,
            sub_activity: updateTicketValues?.project?.sub_activity?.map(item => item.id),
            debris_type: updateTicketValues?.debris_type?.id,
            truck: updateTicketValues?.truck?.id,
            truck_capacity: updateTicketValues?.truck?.bed_capacity,
            field_monitor: updateTicketValues?.field_monitor?.id,
            site_monitor: updateTicketValues?.site_monitor?.id,
            load_date: updateTicketValues?.load_date ? dayjs(updateTicketValues?.load_date, "YYYY/MM/DD") : null,
            load_time: updateTicketValues?.load_time ? dayjs(updateTicketValues?.load_time, "HH:mm:ss") : null,
            disposal_date: updateTicketValues?.disposal_date ? dayjs(updateTicketValues?.disposal_date, "YYYY/MM/DD") : null,
            disposal_time: updateTicketValues?.disposal_time ? dayjs(updateTicketValues?.disposal_time, "HH:mm:ss") : null,
            manual_ticket_number: updateTicketValues?.manual_ticket_number,
            site_type: updateTicketValues?.disposal_site?.site_type,
            address: updateTicketValues?.addresses.map(item => item.address),
            latitude: updateTicketValues?.addresses.map(item => item.latitude),
            longitude: updateTicketValues?.addresses.map(item => item.longitude),
            field_monitor_notes: updateTicketValues?.field_monitor_notes,
            site_monitor_notes: updateTicketValues?.site_monitor_notes,
            unit_of_measure: updateTicketValues?.unit_of_measure,
          });
          setFieldMonitorSelected(updateTicketValues?.field_monitor?.id);
          setSiteMonitorSelected(updateTicketValues?.site_monitor?.id);
          setTruckNumberSelected(updateTicketValues?.truck?.id);
          setAddress(updateTicketValues?.addresses.map(item => item.address));
          setAddressLatAndLong([updateTicketValues?.addresses.map(item => item.latitude), updateTicketValues?.addresses.map(item => item.longitude)]);
        }
      }
    , [updateTicketValues]);

    // useEffect(() => {
    //   if (updateTicketValues) {
    //     setLoadTime(dayjs(updateTicketValues?.load_time, "HH:mm:ss"));
    //     setDisposalTime(dayjs(updateTicketValues?.disposal_time, "HH:mm:ss"));
    //     setLoadDate(dayjs(updateTicketValues?.load_date, "YYYY/MM/DD"));
    //     setDisposalDate(dayjs(updateTicketValues?.disposal_date, "YYYY/MM/DD"));
    //   }
    // }, [updateTicketValues]);

    useEffect(() => {
      if (updateTicketValues && Object.keys(updateTicketValues).length > 0) {
        
        const fields = updateTicketValues?.debris_type;
        setFields(fields);
        setShowUpdateTicketForm(true);

      }
    }
  , [updateTicketValues]);

    




    useEffect(() => {
      if (updateTicketValues  && fields && Object.keys(fields).length > 0 && fields?.rate_matrix_fields) {
        // Iterate over the keys in the fields state
      
        
        Object.keys(fields?.rate_matrix_fields).forEach((fieldKey) => {
          // Check if the fieldKey is also in updateTicketValues
          if (updateTicketValues.hasOwnProperty(fieldKey)) {
            if (fieldKey === "disposal_site" || fieldKey === "debris_type" || fieldKey === "hazard_type") {
              // Handle fields that might be objects with an id
              form.setFieldsValue({
                [fieldKey]: updateTicketValues[fieldKey] ? updateTicketValues[fieldKey].id : null,
              });

              if (fieldKey === "disposal_site") {
                
                setDisposalSiteSelected(updateTicketValues[fieldKey]?.id || null);
                
                form.setFieldsValue({
                  disposal_site: updateTicketValues[fieldKey]?.id,
                  site_type: updateTicketValues[fieldKey]?.site_type,
                });
              } else if (fieldKey === "hazard_type") {
                setHazardTypeSelected(updateTicketValues[fieldKey]?.id || null);
                form.setFieldsValue({
                  hazard_type: updateTicketValues[fieldKey]?.id,
                });
              }

              if (fieldKey === "tipping_fee") {
                form.setFieldsValue({
                  [fieldKey]: updateTicketValues["tipping_fees"] || 0,
                });
              }

            } else {
              // Handle other fields
              form.setFieldsValue({
                [fieldKey]: updateTicketValues[fieldKey],
              });
            }
          }
        });
      }
    }, [updateTicketValues, fields]);
    
    



    //----------------------- Fetch Data -----------------------//


    const fetchProjects = async (query = '', page = 1) => {
        main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&is_active=true&page=${page}`)
        .then((response) => {
            const result = response?.data?.results;
            let options = result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));
            if (updateTicketValues?.project) {
              const selectedOption = {
                ...updateTicketValues.project,
                label: updateTicketValues.project.name.charAt(0).toUpperCase() + updateTicketValues.project.name.slice(1),
                value: updateTicketValues.project.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }
            setProjectOptions(options);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
    };

    const fetchSubActivityTypes = async (page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `projects=${selectedProjectForUpdateForm}`;
        try {
          const response = await main_api.get(`${adminAPIsEndPoints.LIST_SUB_ACTIVITY(query)}&is_active=true&page=${page}`);
          const result = response?.data?.results;
    
          if (result) {
            // Format options with proper labels and values
            let options = result.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));
    
            // Check if updateTicketValues.sub_activity contains IDs and update the selected options
            const selectedOptionIds = updateTicketValues?.sub_activity || [];
            const selectedOptions = options.filter(option => selectedOptionIds.includes(option.value));
    
            // Ensure selected options are present at the start of the options list
            options = [...selectedOptions, ...options.filter(item => !selectedOptionIds.includes(item.value))];
    
            setSubActivityTypeOptions(options);
          } else {
            setSubActivityTypeOptions([]);
          }
        } catch (error) {
          pushNotification(error?.response?.data?.detail, "error");
        }
      } else {
        setSubActivityTypeOptions([]);
      }
    };
    

    const fetchDebrisTypes = async ( page = 1) => {
      if (selectedProjectForUpdateForm) {
        
        const query =  `disposal_sites__projects=${selectedProjectForUpdateForm}&is_active=true&page=${page}`

        main_api.get(adminAPIsEndPoints.LIST_DEBRIS(query))
        .then((response) => {
            const result = response?.data?.results;
            let options = result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));
            if (updateTicketValues?.debris_type) {
              const selectedOption = {
                ...updateTicketValues.debris_type,
                label: updateTicketValues.debris_type.name.charAt(0).toUpperCase() + updateTicketValues.debris_type.name.slice(1),
                value: updateTicketValues.debris_type.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }
            // setSelectedDebrisTypeData(result);
            setDebrisTypeOptions(options);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setDebrisTypeOptions([]);
      }
    };

    const fetchDisposalSites = async (page = 1) => {
      if (selectedProjectForUpdateForm && selectedDebrisTypeForUpdateForm) {
        
        const query = `projects=${selectedProjectForUpdateForm}&debris_type=${selectedDebrisTypeForUpdateForm}&is_active=true&page=${page}`
        main_api.get(adminAPIsEndPoints.LIST_DISPOSAL_SITES(query))
        .then((response) => {
            const result = response?.data?.results;
            let options = result?.map(item => ({
              ...item,
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));

            if (updateTicketValues?.disposal_site) {
              const selectedOption = {
                ...updateTicketValues.disposal_site,
                label: updateTicketValues.disposal_site.name.charAt(0).toUpperCase() + updateTicketValues.disposal_site.name.slice(1),
                value: updateTicketValues.disposal_site.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }

            setDisposalSiteOptions(options);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setDisposalSiteOptions([]);
      }
    };

    const fetchFieldMonitors = async (page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `projects=${selectedProjectForUpdateForm}&page=${page}`
        main_api.get(adminAPIsEndPoints.LIST_MONITORS(query))
          .then((response) => {
              const result = response?.data?.results;
              let fieldMonitorOptions = result?.map(item => ({
                label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
                value: item.id,
              }));
              if (updateTicketValues?.field_monitor !== null && updateTicketValues?.field_monitor !== undefined) {
                const selectedOption = {
                  // ...updateTicketValues.field_monitor,
                  label: updateTicketValues.field_monitor.name.charAt(0).toUpperCase() + updateTicketValues.field_monitor.name.slice(1),
                  value: updateTicketValues.field_monitor.id,
                };
                fieldMonitorOptions = [selectedOption, ...fieldMonitorOptions.filter(item => item.id !== selectedOption.value)];
              }
              setFieldMonitorOptions(fieldMonitorOptions);
          })
          .catch((error) => {
              pushNotification(error?.response?.data?.detail, "error");
          });
      } else {
        setFieldMonitorOptions([]);
      }
    };

    const fetchSiteMonitors = async ( page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `projects=${selectedProjectForUpdateForm}&page=${page}`
        main_api.get(adminAPIsEndPoints.LIST_MONITORS(query))
        .then((response) => {
            const result = response?.data?.results;
            let siteMonitorOptions = result?.map(item => ({
              label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
              value: item.id,
            }));
            
            if (updateTicketValues?.site_monitor !== null && updateTicketValues?.site_monitor !== undefined) {
              const selectedOption = {
                // ...updateTicketValues.site_monitor,
                label: updateTicketValues.site_monitor.name.charAt(0).toUpperCase() + updateTicketValues.site_monitor.name.slice(1),
                value: updateTicketValues.site_monitor.id,
              };
              siteMonitorOptions = [selectedOption, ...siteMonitorOptions.filter(item => item.id !== selectedOption.value)];
            }
            setSiteMonitorOptions(siteMonitorOptions);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setSiteMonitorOptions([]);
      }
  };


    const fetchTruckNumber = async ( page = 1) => {
      if (selectedProjectForUpdateForm) {
        const query = `project=${selectedProjectForUpdateForm}&is_active=true&&page=${page}`
        main_api.get(`${adminAPIsEndPoints.LIST_TRUCK(query)}&page=${page}`)
        .then((response) => {
            const result = response?.data?.results;
            let options = result?.map(item => ({
              ...item,
              label: item.truck_number,
              value: item.id,
            }));
            if (updateTicketValues?.project) {
              const selectedOption = {
                ...updateTicketValues.project,
                label: updateTicketValues.project.name.charAt(0).toUpperCase() + updateTicketValues.project.name.slice(1),
                value: updateTicketValues.project.id,
              };
              options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
            }
            
            setTruckNumberOptions(options);
        })
        .catch((error) => {
            pushNotification(error?.response?.data?.detail, "error");
        });
      } else {
        setTruckNumberOptions([]);
      }
  };



const fetchHazardTypes = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_HAZARD_TYPE(query)}&page=${page}`)
    .then((response) => {
        const result = response?.data?.results;
        let options = result?.map(item => ({
          ...item,
          label: item.type.charAt(0).toUpperCase() + item.type.slice(1),
          value: item.id,
        }));
        if (updateTicketValues?.hazard_type) {
          const selectedOption = {
            ...updateTicketValues.hazard_type,
            label: updateTicketValues.hazard_type.name.charAt(0).toUpperCase() + updateTicketValues.hazard_type.name.slice(1),
            value: updateTicketValues.hazard_type.id,
          };
          options = [selectedOption, ...options.filter(item => item.id !== selectedOption.value)];
        }
        setHazardTypeOptions(options);
    })
    .catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
    });
};


const generateUnitOptions = () => {
  return Object.entries(fields)
      .filter(([key, value]) => key.includes("_unit") && value === true)
      .map(([key]) => {
          const label = key.replace("_unit", ""); 
          const capitalizedLabel = label.toUpperCase();
          return {
              label: capitalizedLabel,
              value: key.replace("_unit", ""),
          };
      });
};

useEffect(() => {
  const unitOptions = generateUnitOptions();
  setUnitOptions(unitOptions);
  }
, [fields]);

    useEffect(() => {
        fetchProjects();
        
        fetchHazardTypes();
    }, []);

    useEffect(() => {
      fetchSubActivityTypes();
      fetchDebrisTypes();
      fetchFieldMonitors();
      fetchSiteMonitors();
      fetchTruckNumber();
    }, [selectedProjectForUpdateForm]);

    useEffect(() => {
      fetchDisposalSites();
    }, [selectedDebrisTypeForUpdateForm, selectedProjectForUpdateForm]);


    useEffect(() => {
      if(addressLatAndLong){

        let latitude = addressLatAndLong[0] || null;
        form.setFieldValue("latitude", latitude);

        let longitude = addressLatAndLong[1] || null;
        form.setFieldValue("longitude", longitude);
      }
    }, [addressLatAndLong]);

    useEffect(() => {
      if(addressLatAndLong && disposalSiteAddressLatAndLong){
        const lat1 = addressLatAndLong[0];
        const lon1 = addressLatAndLong[1];
        const lat2 = disposalSiteAddressLatAndLong[0];
        const lon2 = disposalSiteAddressLatAndLong[1];
        const calculatedDistance = distanceCalculator(lat1, lon1, lat2, lon2);
        
        setDistance(calculatedDistance);
        form.setFieldsValue({ distance: calculatedDistance });
      }
    }, [addressLatAndLong, disposalSiteAddressLatAndLong]);
    //----------------------- Event Handlers -----------------------//


    const handleProjectChange = (value) => {
        setSelectedSubActivityForUpdateForm(null);
        setSelectedDebrisTypeForUpdateForm(null);
        const data = projectOptions.filter(item => item.id === value)[0];
        const finalDisposalSite = data?.disposal_site?.find(site => site.site_type === "final");

        if (finalDisposalSite) {
            setDisposalSiteAddressLatAndLong([finalDisposalSite.latitude, finalDisposalSite.longitude]);
        } else {
            setDisposalSiteAddressLatAndLong([null, null]);
        }
        setSelectedProjectData(data);
        // setProjectSelected(value);
        setSelectedProjectForUpdateForm(value);
        form.setFieldsValue({ project: value });
        form.setFieldsValue({ sub_activity: null });
        form.setFieldsValue({ debris_type: null });
    }

    const handleSubActivityChange = (value) => {
        // setSubActivitySelected(value);
        setSelectedSubActivityForUpdateForm(value);
        form.setFieldsValue({ sub_activity: value });
    }

    const handleDebrisTypeChange = (value) => {
        // setDebrisTypeSelected(value);
        setSelectedDebrisTypeForUpdateForm(value);
        const selectedDebris = debrisTypeOptions.filter(item => item.id === value)[0];
        
        setRequiredPhotoCount({
          field_photo_count: selectedDebris?.field_photo_count,
          site_photo_count: selectedDebris?.site_photo_count
        });
        setFields(selectedDebris);
        setSelectedDebrisTypeData(selectedDebris);
        form.setFieldsValue({ debris_type: value });
    }

    const handleDisposalSiteChange = (value) => {
        setDisposalSiteSelected(value);
        form.setFieldsValue({ disposal_site: value });
    }

    const handleFieldMonitorChange = (value) => {
        setFieldMonitorSelected(value);
        form.setFieldsValue({ field_monitor: value });
    }

    const handleSiteMonitorChange = (value) => {
        setSiteMonitorSelected(value);
        form.setFieldsValue({ site_monitor: value });
    }

    const handleTruckNumberChange = (value) => {
        setTruckNumberSelected(value);
        const selectedTruck = truckNumberOptions.filter(item => item.id === value)[0];
        const capacity = selectedTruck?.bed_capacity;
        setTruckCapacity(capacity);
        const loadCall = updateTicketValues?.load_call || form.getFieldValue("load_call");
        
        const bedCapacity = selectedTruck?.bed_capacity;
        if (loadCall && bedCapacity) {
          const loadVolume = bedCapacity * loadCall / 100;
          setTruckLoadVolume(loadVolume);
          form.setFieldsValue({ truck_load_volume: loadVolume });
        } else {
          setTruckLoadVolume(0);
          form.setFieldsValue({ truck_load_volume: 0 });
        }
        form.setFieldsValue({ truck: value });
        form.setFieldsValue({ truck_capacity: capacity });
    }

    const handleHazardTypeChange = (value) => {
        setHazardTypeSelected(value);
        form.setFieldsValue({ hazard_type: value });
    }

    // const handleLoadTime = (value) => {
    //   console.log("load time:", value);
    //   setLoadTime(dayjs(value, "HH:mm:ss"));
    // }

    // const handleDisposalTime = (value) => {
    //   console.log("disposal time:", value);
      
    //   setDisposalTime(dayjs(value, "HH:mm:ss"));
    // }

    // const handleLoadDate = (value) => {
    //   console.log("load date:", value);
    //   setLoadDate(dayjs(value, "MM/DD/YYYY"));
    // }

    // const handleDisposalDate = (value) => {
    //   console.log("disposal date:", value);
    //   setDisposalDate(dayjs(value, "MM/DD/YYYY"));
    // }
    


    //-------------------------- Add Click --------------------------//

    const handleAddClick = () => {
      const projectValue = form.getFieldValue('project');
      const subActivityValue = form.getFieldValue('sub_activity');
      const debrisTypeValue = form.getFieldValue('debris_type');
      if (!projectValue) {
        message.error("Please select your Project.");
        return;
      }
      if (!subActivityValue) {
        message.error("Please select Sub Activity.");
        return;
      }
      if (!debrisTypeValue) {
        message.error("Please select Debris Type.");
        return;
      }
      setShowUpdateTicketForm(true);
    }

 
    //----------------------- Dynamic Form Fields -----------------------//
   

    const generateFormItem = (fieldKey, fieldConfig, isRequired, isOptional) => {
      const { label, type } = fieldConfig;
      const rules = getFieldValidationRules(type, label, isRequired, isOptional);
  
      let inputComponent;
      if (isRequired === false && isOptional === false) {
        return null;
      }
      switch (type) {
          case "numeric":
          case "numeric_currency":
          case "quantity_required":
              inputComponent = <InputNumber placeholder='Enter Here' style={{width:"100%"}} />;
              break;
          case "dropdown":
              inputComponent = (
                <>
                
                  <PaginatedSelect
                      fetchData={fieldKey === "hazard_type" ? fetchHazardTypes : fieldKey === "disposal_site" ? fetchDisposalSites : null}
                      placeholder="Select"
                      options={fieldKey === "hazard_type" ? hazardTypeOptions : fieldKey === "disposal_site" ? disposalSiteOptions : []}
                      value={fieldKey === "hazard_type" ? hazardTypeSelected : fieldKey === "disposal_site" ? disposalSiteSelected : null}
                      onChange={fieldKey === "hazard_type" ? handleHazardTypeChange : fieldKey === "disposal_site" ? handleDisposalSiteChange : null}
                  />
                  </>
              );
              break;
          case "yes_no":
              inputComponent = (
                  <Radio.Group style={{display:"flex", flexDirection:"row"}}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                  </Radio.Group>
              );
              break;
          default:
              inputComponent = <Input placeholder='Enter Here' />;
              break;
      }
  
      return (
          <FormItem key={fieldKey} label={label} name={fieldKey} rules={rules}>
              {inputComponent}
              {fieldKey === "disposal_site" && (
                    <FormItem 
                      name="site_type" 
                      style={{marginTop:"10px"}}
                    >
                      <Radio.Group>
                        <Radio value="final">Final</Radio>
                        <Radio value="temporary">Temporary</Radio>
                      </Radio.Group>
          
                    </FormItem>
                  )}
          </FormItem>
      );
  };



  return (
    <CustomModal  
    open={isModalOpen}
    title={title}
    width="630px"
    height={showUpdateTicketForm === true ? "580px" : "380px"}
    onCancel={() => {
      form.resetFields();
      setShowUpdateTicketForm(false);
      setSelectedProjectForUpdateForm(null);
      setSelectedSubActivityForUpdateForm(null);
      setSelectedDebrisTypeForUpdateForm(null);
      setProjectSelected(null);
      setSubActivitySelected(null);
      setDebrisTypeSelected(null);
      setDisposalSiteSelected(null);
      setHazardTypeSelected(null);
      setFieldMonitorSelected(null);
      setSiteMonitorSelected(null);
      setTruckNumberSelected(null);
      setDistance(0);
      setTruckCapacity(0);
      setTruckLoadVolume(0);
      setFields({});
      setRequiredPhotoCount({});
      setFile(null);
      setUploadedFiles([]);
      setSavedFileUrl(null);
      setAddress("");
      setAddressLatAndLong([null,null]);
      setDisposalSiteAddressLatAndLong([null,null]);
      setModalOpen(false);
    }}
    
    footer={null}
    maskClosable={false}
    // isScrollable={true}
  >
  <Divider style={{width:"107.9%", position:"relative", top:"0px", right:"24px",borderTop:"1px solid #DEE2E6"}}/>
    
    <Form name="updateTicketForm" onFinish={onFinish} form={form} layout="vertical" >
      <FormWrapper height={showUpdateTicketForm === true ? "585px" : "285"}>
       {showUpdateTicketForm === false  &&  !updateTicketValues &&(
        <>
        
        <FormItem 
            name="project" 
            label="Project"
            rules={[
              {
                required: true,
                message: "Please Select your Project",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchProjects} placeholder="Select" options={projectOptions} value={selectedProjectForUpdateForm} onChange={handleProjectChange} style={{width:"100%"}}/>
  
        </FormItem>
      
        <FormItem
            name="sub_activity" 
            label="Sub Activity"
            rules={[
              {
                required: true,
                message: "Please Select Sub Activity",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchSubActivityTypes} placeholder="Select" options={subActivityTypeOptions} value={selectedSubActivityForUpdateForm} onChange={handleSubActivityChange} style={{width:"100%"}}/>

        </FormItem>
        <FormItem 
            name="debris_type" 
            label="Debris Type"
            rules={[
              {
                required: true,
                message: "Please Select Debris Type",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchDebrisTypes} placeholder="Select" options={debrisTypeOptions} value={selectedDebrisTypeForUpdateForm} onChange={handleDebrisTypeChange} style={{width:"100%"}}/>
  
        </FormItem>
        </>
        )}
   
        {showUpdateTicketForm && (
          <>
          <div style={{display:"flex", flexDirection:"column", background:"#EBF0F7", padding:"20px", marginBottom:"20px"}}>
              <span style={{display:"flex", flexDirection:"row" , alignItems: "center", justifyContent:"space-evenly"}} >

                <span style={{display:"flex", flexDirection:"row" , alignItems: "center"}}>
                  <Heading text="Project:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
                  <Heading text={updateTicketValues?.project?.name || selectedProjectData?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>
                <span style={{display:"flex", flexDirection:"row", alignItems: "center", marginLeft:"30px"}}>
                  <Heading text="Debris Type:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
            
                  <Heading text={updateTicketValues?.debris_type?.name || selectedDebrisTypeData?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>
                
              </span>
              
              {/* <span style={{display:"flex", flexDirection:"row" , alignItems: "center", marginTop:"20px",  justifyContent:"space-evenly"}}>

                <span style={{display:"flex", flexDirection:"row", alignItems: "center", marginLeft:"30px"}}>
                  <Heading text="Event:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
                  <Heading text={updateTicketValues?.project?.event?.name || selectedProjectData?.event?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>

                <span style={{display:"flex", flexDirection:"row", alignItems: "center", marginLeft:"30px"}}>
                  <Heading text="Debris Type:" margin="0px 0px 0px 5px" fontSize="0.875rem" color="#344054" />
                  <Heading text={updateTicketValues?.debris_type?.name || selectedDebrisTypeData?.name} margin="0px 0px 0px 10px" fontSize="1rem" color="#3669AE" />
                </span>
            

              </span> */}
          </div>
          <FormItem 
            name="truck" 
            label="Truck Number"
            rules={[
              {
                required: true,
                message: "Please Enter Ticket Number",
              },
            ]} 
          >
            <PaginatedSelect fetchData={fetchTruckNumber} placeholder="Select" options={truckNumberOptions} value={truckNumberSelected} onChange={handleTruckNumberChange} style={{width:"100%"}}/>

          </FormItem>
        
          
          <div style={{display:"flex", flexDirection:"row"}}>

          <FormItem
            name="field_monitor" 
            label="Field Monitor"
            rules={[
              {
                required: true,
                message: "Please Select a Field Monitor",
              },
            ]}
          >
            <PaginatedSelect fetchData={fetchFieldMonitors} placeholder="Select" options={fieldMonitorOptions} value={fieldMonitorSelected} onChange={handleFieldMonitorChange} />
          </FormItem>
          <FormItem
            name="site_monitor" 
            label="Site Monitor"
            style={{marginLeft:"10px"}}
            rules={[
              {
                required: true,
                message: "Please Select a Site Monitor",
              },
            ]}
          >
            <PaginatedSelect fetchData={fetchSiteMonitors} placeholder="Select" options={siteMonitorOptions} value={siteMonitorSelected} onChange={handleSiteMonitorChange} />
          </FormItem>
          </div>
          <div style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="load_date"
              label="Load Date"
              rules={[
                {
                  required: true,
                  message: "Please Select Load Date",
                },
              ]}
            >
              <DatePicker 
                placeholder="MM/DD/YYYY" 
                format="MM/DD/YYYY"
                style={{width:"285px"}}
              />
            </FormItem>
          
            <FormItem
              name="load_time"
              label="Load Time"
              style={{marginLeft:"10px"}}
              rules={[
                {
                  required: true,
                  message: "Please Select Time",
                },
              ]}
            >
              <TimePicker placeholder="HH:MM:SS" style={{ width: "285px"}} format="HH:mm:ss"/>

            </FormItem>
          </div>

          <div style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="disposal_date"
              label="Disposal Date"
              dependencies={['load_date']}
              rules={[
                {
                  required: true,
                  message: "Please select Disposal Date",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    const loadDate = getFieldValue('load_date');
                    if (!value || !loadDate || value.isAfter(loadDate)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Disposal Date cannot be before Load Date'));
                  },
                }),
              ]}
            >
              <DatePicker 
                placeholder="MM/DD/YYYY" 
                format="MM/DD/YYYY"
                style={{width:"285px"}} 
              />
            </FormItem>
           
            <FormItem
              name="disposal_time"
              label="Disposal Time"
              style={{marginLeft:"10px"}}
              rules={[
                {
                  required: true,
                  message: "Please Select Time",
                },
              ]}
            >
              <TimePicker placeholder="HH:MM" style={{ width: "285px" }} format="HH:mm:ss" />
            </FormItem>
            
          </div>
          <div 
            style={{display:"flex", flexDirection:"row"}}
          >
          <FormItem
            name="manual_ticket_number"
            label="Manual Ticket Number"
            rules={
              [
                {
                  required: false,
                  message: "Please Enter Ticket Number",
                },
                {
                  pattern: /^[a-zA-Z0-9]*$/, // Only allows alphanumeric characters
                  message: "Ticket Number must be alphanumeric",
                },
                {
                  min: 2,
                  max: 50,
                  message: "Ticket Number must be less than 50 characters",
                },
              ]
            }
          >
            <Input placeholder="Enter Here" />
          </FormItem>

          <FormItem
            name="unit_of_measure"
            label="Unit of Measure"
            style={{marginLeft:"10px"}}
            rules={[
              {
                required: true,
                message: "Please Select Unit of Measure",
              }
            ]}
          >
            <Select placeholder="Select" defaultValue={unitOptions[0]} options={unitOptions} style={{width:"100%"}} />
          </FormItem>

          </div>
          <LocationSelector
            address={address}
            setAddress={setAddress}
            setAddressLatAndLong={setAddressLatAndLong}
            form={form}
            checked={false}
            label='Address'
            addressLatAndLong={addressLatAndLong}
            name='address'
          />
          
          <span style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="latitude"
              label="Latitude"
              >
              <Input value={addressLatAndLong[0] || null} disabled={true}/>
            </FormItem>
            <FormItem
              name="longitude"
              label="Longitude"
              style={{marginLeft:"10px"}}
            >
              <Input value={addressLatAndLong[1] || null} disabled={true}/>
            </FormItem>
          </span>
            <FormItem
              name="truck_capacity"
              label="Truck Capacity"
            >
                <Input value={truckCapacity} disabled={true}/>
            </FormItem>
           
          <div style={{display:"flex", flexDirection:"row"}}>
            <FormItem
              name="truck_load_volume"
              label="Truck Load Volume Cubic Yards"
            >
                <Input value={truckLoadVolume} disabled={true}/>
            </FormItem>
            <FormItem
              name="distance"
              label="Distance"
              style={{marginLeft:"10px"}}
            >
              <Input value={distance} disabled={true} />
            </FormItem>
          </div>
          

          
          
          {Object.entries(fields?.rate_matrix_fields).map(([key, requirement]) => {
                const isRequired = requirement === "required" ? true : false;
                const isOptional = requirement === "optional" ? true : false;
                const fieldConfig = fieldsMap[key];

                if (fieldConfig) {
                    return generateFormItem(key, fieldConfig, isRequired, isOptional);
                }
                return null;
            })}

          <FormItem 
            name="field_monitor_notes" 
            label="Field Monitor Notes"
          >
            <TextArea placeholder="Add Notes" />
          </FormItem>
          <FormItem 
            name="site_monitor_notes" 
            label="Site Monitor Notes"
          >
            <TextArea placeholder="Add Notes" />
          </FormItem>

          <FormItem
            name="attachments"
            label="Attachments"
          >
            <CustomUploader file={file} setFile={setFile} uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} directory={"ticket"} />
          </FormItem>
          </>
        )}
     
  
        </FormWrapper>
        <Divider/>
      <SaveContainer className="d-flex justify-content-end">
        <CustomButton
          btnText={"Cancel"}
          margin="0px 5px"
          noBackground
          hideIcon={true}
          onClick={() => {
            form.resetFields();
            setShowUpdateTicketForm(false);
            setSelectedProjectForUpdateForm(null);
            setSelectedSubActivityForUpdateForm(null);
            setSelectedDebrisTypeForUpdateForm(null);
            setProjectSelected(null);
            setSubActivitySelected(null);
            setDebrisTypeSelected(null);
            setDisposalSiteSelected(null);
            setHazardTypeSelected(null);
            setFieldMonitorSelected(null);
            setSiteMonitorSelected(null);
            setTruckNumberSelected(null);
            setDistance(0);
            setTruckCapacity(0);
            setTruckLoadVolume(0);
            setFields({});
            setRequiredPhotoCount({});
            setFile(null);
            setUploadedFiles([]);
            setSavedFileUrl(null);
            setAddress("");
            setAddressLatAndLong([null,null]);
            setDisposalSiteAddressLatAndLong([null,null]);
            setModalOpen(false);
          }}
        />
        {
          showUpdateTicketForm === true ? (
            <CustomButton btnText={"Save Changes"} color={"white"} type="submit" hideIcon={true} />
          ) 
          :
          (
            <Button
              type="button"
              onClick={handleAddClick}
              style={{background:"#3669AE", color:"white", border:"none", width:"80px", height:"40px"}}
            >
              Add
            </Button>
            // <CustomButton btnText={"Add"} color={"white"} type="button" onClick={() => setShowUpdateTicketForm(true)} hideIcon={true} />
          )
        }
      </SaveContainer>
    </Form>
  </CustomModal>
  )
}
const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
    return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
  };
  
  const FormItem = styled(Form.Item)`
  width: 100%;
  `;

  const DynamicFormItem = styled(Form.Item)`
  flex: 1 1 calc(33.333% - 16px); /* Adjust based on the number of columns desired */
  box-sizing: border-box;
  
  @media (max-width: 768px) {
      flex: 1 1 calc(50% - 16px); /* 2 columns on smaller screens */
  }

  @media (max-width: 480px) {
      flex: 1 1 100%; /* Full width on very small screens */
  }
`;
  
  const FormWrapper = styled.div`
  height: ${(props) => props.height};
  width:  600px;
  padding: 5px;
  overflow: auto;
  
  /* Custom scrollbar styling */
    &::-webkit-scrollbar {
      width: 4px;
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #3669AE;
      border-radius: 2px;
      min-height: 30px; /* Ensure the thumb isn't too small */
    }
  
    &::-webkit-scrollbar-track {
      background-color: transparent;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  `;
  
  const SaveContainer = styled.div`
  position: absolute;
  bottom: 4px;
  right: 4px;
  width: 625px;
  border-top: 1px solid #E0E0E0;
  background-color: white;
  padding: 10px 20px;
  `;
  
  
  
  