export const adminAPIsEndPoints = {
  //----------------------- Auth APIs -----------------------//
  
  LOGIN: "/users/login/",
  RESET_PASSWORD: "",
  CREATE_USER: "/users/profile/",
  LIST_USER: (query) => `/users/profile/?${query}`,
  UPDATE_USER: (id) => `/users/profile/${id}/`,
  CHANGE_PASSWORD: "/users/profile/reset_password/",
  USER_DETAILS: "/users/profile/details/",

  // Get Monitors

  LIST_MONITORS: (query) => `/users/profile/monitor/?${query}`,
  // Get Clients

  LIST_CLIENTS: `/users/profile/client/`,

  // Get Contractors

  LIST_CONTRACTORS: (query) => `/users/profile/contractor/?${query}`,
  
  
  //----------------------- Dashboard APIs -----------------------//

  DASHBOARD_STATS: "/administration/dashboard/stats/",
  
  //----------------------- User Role APIs -----------------------//

  LIST_ROLES: "/users/role/",

  //----------------------- User Position APIs ---------------------//

  ADD_POSITION: "/users/position/",
  LIST_POSITION: (query) => `/users/position/?${query}`,
  UPDATE_POSITION: (id) => `/users/position/${id}/`,
  DELETE_POSITION: (id) => `/users/position/?${id}/`,

  //----------------------- Debris Type APIs -----------------------//

  ADD_DEBRIS: "/administration/debris/",
  LIST_DEBRIS: (query) => `/administration/debris/?${query}`,
  UPDATE_DEBRIS: (id) => `/administration/debris/${id}/`,
  DELETE_DEBRIS: (id) => `/administration/debris/${id}/`,

  //----------------------- Truck Type APIs -----------------------//

  ADD_TRUCK_TYPE: "/administration/truck-type/",
  LIST_TRUCK_TYPE: (query) => `/administration/truck-type/?${query}`,
  UPDATE_TRUCK_TYPE: (id) => `/administration/truck-type/${id}/`,
  DELETE_TRUCK_TYPE: (id) => `/administration/truck-type/${id}/`,

  //----------------------- Sub-Activity APIs -----------------------//

  ADD_SUB_ACTIVITY: "/administration/sub-activity/",
  LIST_SUB_ACTIVITY: (query) => `/administration/sub-activity/?${query}`,
  UPDATE_SUB_ACTIVITY: (id) => `/administration/sub-activity/${id}/`,
  DELETE_SUB_ACTIVITY: (id) => `/administration/sub-activity/${id}/`,

  //----------------------- Hazard Type APIs -----------------------//

  ADD_HAZARD_TYPE: "/administration/hazard-type/",
  LIST_HAZARD_TYPE: (query) => `/administration/hazard-type/?${query}`,
  UPDATE_HAZARD_TYPE: (id) => `/administration/hazard-type/${id}/`,
  DELETE_HAZARD_TYPE: (id) => `/administration/hazard-type/${id}/`,

  //----------------------- Hazard Name APIs -----------------------//

  ADD_HAZARD_NAME: "/administration/hazard-name/",
  LIST_HAZARD_NAME: (query) => `/administration/hazard-name/?${query}`,
  UPDATE_HAZARD_NAME: (id) => `/administration/hazard-name/${id}/`,
  DELETE_HAZARD_NAME: (id) => `/administration/hazard-name/${id}/`,

  //----------------------- Menu Setup APIs -----------------------//

  ADD_MENU: "/users/menu/",
  LIST_MENU: (query) => `/users/menu/?${query}`,
  UPDATE_MENU: (id) => `/users/menu/${id}/`,
  DELETE_MENU: (id) => `/users/menu/${id}/`,

  //----------------------- Event Management APIs -----------------------//

  ADD_EVENT: "/ticketing/event/",
  LIST_EVENT: (query) => `/ticketing/event/?${query}`,
  UPDATE_EVENT: (id) => `/ticketing/event/${id}/`,
  DELETE_EVENT: (id) => `/ticketing/event/${id}/`,

  //----------------------- Project Management APIs -----------------------//

  // Manage Project

  ADD_PROJECT: "/ticketing/project/",
  LIST_PROJECT: (query) => `/ticketing/project/?${query}`,
  // LIST_PROJECT : (query) => {
  //   return query ? `/ticketing/project/?${query}&page=1` : `/ticketing/project/?page=1`;
  // },
  UPDATE_PROJECT: (id) => `/ticketing/project/${id}/`,
  DELETE_PROJECT: (id) => `/ticketing/project/${id}/`,

  // Map User

  ADD_USER_TO_PROJECT: "/ticketing/project/assign_user_to_project/",
  LIST_ASSIGNED_USERS_ON_PROJECT: (query) => `/ticketing/project/list_assigned_users_on_project/?${query}`,
  LIST_AVAILABLE_USERS_ON_PROJECT: (query) => `/ticketing/project/list_available_users_on_project/?${query}`,
  REMOVE_ASSIGNED_USER_FROM_PROJECT: `/ticketing/project/remove_assigned_user_from_project/`,

  //----------------------- Contractor Management APIs ------------------------//
  
  CREATE_CONTRACTOR: "/users/contractor/",
  LIST_CONTRACTOR: (query) => `/users/contractor/?${query}`,
  UPDATE_CONTRACTOR: (id) => `/users/contractor/${id}/`,

  
  LIST_CONTRACTOR_RATE_MATRIX: (query) => `/ticketing/rate-matrix/?${query}`,
  ADD_CONTRACTOR_RATE_MATRIX: "/ticketing/rate-matrix/",
  UPDATE_CONTRACTOR_RATE_MATRIX: (id) => `/ticketing/rate-matrix/${id}/`,
  DELETE_CONTRACTOR_RATE_MATRIX: (id) => `/ticketing/rate-matrix/${id}/`,

  //----------------------- Disposal Site Management APIs --------------------//

  LIST_DISPOSAL_SITES: (query) => `/administration/disposal-site/?${query}`,
  ADD_DISPOSAL_SITE: `/administration/disposal-site/`,
  UPDATE_DISPOSAL_SITE: (id) => `/administration/disposal-site/${id}/`,
  DELETE_DISPOSAL_SITE: (id) => `/administration/disposal-site/${id}/`,

  //----------------------- Ticket Management APIs -----------------------//

  ADD_TICKET: "/tickets/",
  LIST_TICKET: (query) => `/tickets/?${query}`,
  UPDATE_TICKET: (id) => `/tickets/${id}/`,
  DELETE_TICKET: (id) => `/tickets/${id}/`,

  //----------------------- Truck Management APIs -----------------------//

  ADD_TRUCK: "/ticketing/truck/",
  LIST_TRUCK: (query) => `/ticketing/truck/?${query}`,
  UPDATE_TRUCK: (id) => `/ticketing/truck/${id}/`,
  DELETE_TRUCK: (id) => `/ticketing/truck/${id}/`,

  //----------------------- Permission APIs -----------------------//

  LIST_PERMISSIONS: (id) => `/users/permissions/${id}/listing/`,
  LIST_ASSIGNED_PERMISSION: `/users/permissions/assigned/`,
  UPDATE_PERMISSION: (id) => `/users/permissions/${id}/`,

  //----------------------- Attachments APIs -----------------------//

  UPLOAD_FILE: "/resources/attachments/",
  LIST_UPLOADED_FILES: (query) => `/resources/attachments/?${query}`,
};

