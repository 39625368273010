
import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Divider } from 'antd';
import styled from 'styled-components';
import HeadingComponent from '../../components/headingComponent/heading';
import { administrationMenuData } from '../../constants/menuData/menuData';
import { generateMenuItems } from '../../util/generateMenuItems';
import CustomMenu from '../../components/customMenu/customMenu';
import DebrisType from '../../components/administrationComponents/debrisType';
import TruckType from '../../components/administrationComponents/truckType';
import SubActivity from '../../components/administrationComponents/subActivity';
import Hazard from '../../components/administrationComponents/hazard';
import UserRole from '../../components/administrationComponents/userRole';
import Position from '../../components/administrationComponents/position';
import MenuSetup from '../../components/administrationComponents/menuSetup';
import EditUserRole from '../../components/modals/administration/userRoles/editUserRole';
import UpdatePosition from '../../components/modals/administration/position/updatePosition';
import { addUserPosition, updateUserPosition } from '../../util/dataService';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { hasPermission } from '../../util/permissionChecker';
import usePermissions from '../../components/hooks/usePermissions';
import { useNavigate } from 'react-router-dom';

export default function Administration() {

  const navigate = useNavigate();
  
  //----------------------- Custom Hooks -----------------------//

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  
  
  //------------------ Function to Generate Menu Items ---------------------//
  const menuItems = generateMenuItems(administrationMenuData);

  //------------------State Management of Modals---------------------//
  const [editUserRoleNameModal, setEditUserRoleNameModal] = useState(false);
  const [permissionPopUp, setPermissionPopUp] = useState(false);
  const [permissionText, setPermissionText] = useState('');



  //------------------State Management of Components---------------------//
  const [activeComponent, setActiveComponent] = useState('Debris Type');

  //---------------------- Components ----------------------//
  const COMPONENTS = {
    'Debris Type': DebrisType,
    'Truck Type': TruckType,
    'Sub-Activity': SubActivity,
    'Hazard': Hazard,
    'User Role': UserRole,
    'Position': Position,
    'Menu Setup': MenuSetup,
  };

  //---------------------- Props Mapping ----------------------//
  const propsMapping = {
    'Debris Type': {setPermissionPopUp,setPermissionText},
    'Truck Type': {setPermissionPopUp, setPermissionText},
    'Sub-Activity': {setPermissionPopUp, setPermissionText},
    'Hazard': {setPermissionPopUp, setPermissionText},
    'User Role': { editUserRoleNameModal, setEditUserRoleNameModal, setPermissionPopUp, setPermissionText},
    'Position': {setPermissionPopUp, setPermissionText},
    'Menu Setup': {},
  };




  //------------------ Function to Check Permission on Initial Load ---------------------//

  
  const checkInitialPermissions = () => {
    const permissionMapping = {
      'Debris Type': 'view_debristype',
      'Truck Type': 'view_trucktype',
      'Sub-Activity': 'view_subactivity',
      'Hazard': 'manage_hazard_type' || 'manage_hazard_name' || 'view_hazardname' ,
      'User Role': 'manage_role',
      'Position': 'view_position',
      'Menu Setup': 'menu_setup',
    };
    
    for (const item of administrationMenuData[0].itemList) {
      const requiredPermission = permissionMapping[item.itemName];
  
      if (!requiredPermission || hasPermission(assignedPermissionsArray, requiredPermission)) {
        
        setActiveComponent(item.itemName);
        return;
      }
    }
  
  };
  
  // Run the permission check on initial load
  useEffect(() => {
    checkInitialPermissions();
  }, [assignedPermissionsArray]);

  useEffect(() => {
    if (!permissionPopUp) {
      checkInitialPermissions();
    }
  }, [permissionPopUp]);

  const initialSelectedKey = administrationMenuData[0].itemList.find(
    (item) => item.itemName === activeComponent
  )?.key;


  //------------------ Function to Handle Menu Select ---------------------//
  // const handleMenuSelect = (item) => {
  //   setActiveComponent(item.label);
  // };

  
  const handleMenuSelect = (item) => {
    const selectedComponent = item.label;
    
    const permissionMapping = {
      'Debris Type': 'view_debristype',
      'Truck Type': 'view_trucktype',
      'Sub-Activity': 'view_subactivity',
      'Hazard': 'manage_hazard_type' || 'manage_hazard_name' || 'view_hazardname',
      'User Role': 'manage_role',
      'Position': 'view_position',
      'Menu Setup': 'menu_setup',
    };

    const requiredPermission = permissionMapping[selectedComponent];

   
      
      if (hasPermission(assignedPermissionsArray, requiredPermission)) {
        setActiveComponent(selectedComponent);
      } else {
        setPermissionText(`You do not have permission to view ${selectedComponent}`);
        setPermissionPopUp(true);
      }
    
  };

  //------------------ Assignment to Render Component ---------------------//
  const ActiveComponent = COMPONENTS[activeComponent];
  const activeComponentProps = propsMapping[activeComponent] || {};
  
  return (
    <div style={{ marginTop: '20px' }}>
      <Row gutter={16} style={{ marginBottom: '16px', marginTop: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <CustomMenu menuItems={menuItems} handleMenuSelect={handleMenuSelect} initialSelectedKey={initialSelectedKey} />
        </div>
        <Col span={6}>
        {/* <UserRole editUserRoleNameModal={editUserRoleNameModal} setEditUserRoleNameModal={setEditUserRoleNameModal} /> */}
          <ActiveComponent {...activeComponentProps} />
        </Col>
      </Row>
      {editUserRoleNameModal && (
        <EditUserRole
          isModalOpen={editUserRoleNameModal}
          title="Edit User Role"
          setModalOpen={setEditUserRoleNameModal}
        />
      )}
      {permissionPopUp && (
        <PermissionModal 
          text={permissionText}
          onclose={() => {
            setPermissionPopUp(false);
          }}
          openModal={permissionPopUp}
        />
      )}
    </div>
  );
}

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1274px;
  height: calc(100vh - 40px);
  max-height: 760px;
  margin: 20px;
  background-color: white;

  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;
