import React from 'react'
import { useLocation } from 'react-router-dom';
import ManageContractors from '../../components/contractorManagementComponents/manageContractors';
import ManageRateMatrix from '../../components/contractorManagementComponents/manageRateMatrix';

export default function ContractorManagement() {

  const location = useLocation();
  const pageName = location.pathname.split('/')[2];
   




  return (
   <>
   {
     pageName === 'manage-contractors' ? (
        <ManageContractors />
     ) 
     : 
     pageName === 'manage-rate-matrix' &&
     (
      <ManageRateMatrix />
     )
   }
   </>
  )
};


