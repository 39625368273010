
//-------------------------------- Permission Checker  --------------------------------//

// export const hasPermission = (userPermissions, requiredPermission) => {
//     return userPermissions.some(permission => permission.codename === requiredPermission);
// };
  


export const hasPermission = (userPermissions, requiredPermission) => {
    if (Array.isArray(requiredPermission)) {
        return requiredPermission.some(permission =>
            userPermissions.some(userPermission => userPermission.codename === permission)
        );
    } else {
        return userPermissions.some(permission => permission.codename === requiredPermission);
    }
};
