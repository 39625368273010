//------------------------------ Custom Hook To Fetch Permissions ------------------------------//

import { useEffect, useState } from "react";
import { adminAPIsEndPoints } from "../../constants/apiEndPoints";
import { main_api } from "../../api/axiosHelper";
import { pushNotification } from "../../util/notification";
import { useSelector } from "react-redux";
import { userHasValidToken } from "../../api/auth";
import { dispatch } from "../../redux/store";
import { getPermissionsData } from "../../redux/slices/permissions";

export default function usePermissions() {
    const [permissions, setPermissions] = useState({});
    const [assignedPermissions, setAssignedPermissions] = useState([]);
    const hasValidToken = userHasValidToken();
    
    const profileState = useSelector((state) => state?.profileData?.profileData);
    
    const currentUserId = profileState?.id;
    //----------------------- Fetch Permissions -----------------------//

    const fetchPermissions = async (id) => {
        if (id) {
            main_api.get(`${adminAPIsEndPoints.LIST_PERMISSIONS(id)}`)
            .then((response) => {
                setPermissions(response.data);
            }).catch((error) => {
                pushNotification(error.response.data.detail, "error");
            });
        }
    };

    //----------------------- Fetch Assigned Permissions -----------------------//

    const fetchAssignedPermissions = async () => {
        main_api.get(adminAPIsEndPoints.LIST_ASSIGNED_PERMISSION)
        .then((response) => {
            setAssignedPermissions(response.data);
        }).catch((error) => {
            pushNotification(error.response.data.detail, "error");
        });
    };

    //----------------------- Use Effect -----------------------//

    useEffect(() => {
        // fetchPermissions();
        if (hasValidToken) {
            fetchAssignedPermissions();
        }
    }, []);

   

    //----------------------- Update Permissions -----------------------//

    // const updatePermissions = async (id, values) => {
    //     main_api.put(adminAPIsEndPoints.UPDATE_PERMISSION(id), values)
    //     .then((response) => {
    //         if (response.status === 200) {
    //             pushNotification("Permissions Updated Successfully!", "success");
    //             fetchPermissions(id);
    //             // fetchAssignedPermissions();
    //         } else {
    //             pushNotification("Failed to Update Permissions!", "error");
    //         }
    //     }).catch((error) => {
    //         pushNotification(error.response.data.detail, "error");
    //     });
    // };

    const updatePermissions = async (id, values) => {
        main_api.put(adminAPIsEndPoints.UPDATE_PERMISSION(id), values)
        .then((response) => {
            if (response.status === 200) {
                pushNotification("Permissions Updated Successfully!", "success");
                fetchPermissions(id)
                .then(async () => {
                   await dispatch(getPermissionsData());
                });
            } else {
                pushNotification("Failed to Update Permissions!", "error");
            }
        }).catch((error) => {
            pushNotification(error.response.data.detail, "error");
        });
    };

    
    

  
    return {permissions, setPermissions, assignedPermissions, setAssignedPermissions, fetchPermissions, fetchAssignedPermissions, updatePermissions};
}
