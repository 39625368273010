

export const accessPermission = [
    { label: "Web", value: "web" },
    { label: "Mobile", value: "mobile" },
    { label: "Both", value: "both" },
  ];

export const status = [
  { label: 'Active', value: true },
  { label: 'In Active', value: false },
];

export const truckWorkStatus = [
  { label: 'Available', value: true },
  { label: 'UnAvailable', value: false },
];

export const truckTypeOptions = [
  { label: 'Simple', value: 'simple' },
  { label: 'U-Shaped', value: 'u-shaped' },
];

export const unitOptions = [
  { label: 'CYDS', value: 'cyds' },
  { label: 'TONS', value: 'tons' },
  { label: 'EACH', value: 'each' },
]

export const unitRequirementOptions = [
  { label: 'Required', value: 'required' },
  { label: 'Optional', value: 'optional' },
  { label: 'Not Required', value: 'not_required' },
];


export const contractorTypeOptions = [
  { label: 'Prime Contractor', value: "prime_contractor" },
  { label: 'Sub Contractor', value: "sub_contractor" },
];

export const ticketStatusOptions = [
  { label: 'Open', value: 'open' },
  { label: 'Closed', value: 'closed' },
  { label: 'Pending', value: 'pending' },
];
export const disposalSiteTypeOptions = [
  { label: 'Final', value: "final" },
  { label: 'Temporary', value: "temporary" },
];

export const attachmentTypeOptions = [
  { label: 'Permit', value: "permit" },
  { label: 'Contract/Lease', value: "contract_lease" },
  { label: 'Permitted Burn', value: "permitted_burn" },
  { label: 'Others', value: "others" },
];



export const countryCodes = [
  { label: "US", value: "+1" },
  { label: "MX", value: "+52" },
  { label: "BR", value: "+55" },
  { label: "AR", value: "+54" },
  { label: "GB", value: "+44" },
  { label: "FR", value: "+33" },
  { label: "DE", value: "+49" },
  { label: "IT", value: "+39" },
  { label: "ES", value: "+34" },
  { label: "RU", value: "+7" },
  { label: "CN", value: "+86" },
  { label: "IN", value: "+91" },
  { label: "JP", value: "+81" },
  { label: "KR", value: "+82" },
  { label: "AU", value: "+61" },
  { label: "NZ", value: "+64" },
  { label: "ZA", value: "+27" },
  { label: "NG", value: "+234" },
  { label: "EG", value: "+20" },
  { label: "SA", value: "+966" },
  { label: "TR", value: "+90" },
];
