import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import ManageProjects from '../../components/projectManagementComponents/manageProjects';
import MapUser from '../../components/projectManagementComponents/mapUser';
import ProjectDetails from '../../components/projectManagementComponents/projectDetails';
import useProjects from '../../components/hooks/useProjects';
import UpdateProjects from '../../components/modals/manageProjects/updateProjects';
import useUsers from '../../components/hooks/useUsers';
import { main_api } from '../../api/axiosHelper';
import { adminAPIsEndPoints } from '../../constants/apiEndPoints';
import { pushNotification } from '../../util/notification';
import { hasPermission } from '../../util/permissionChecker';
import usePermissions from '../../components/hooks/usePermissions';
import HeadingComponent from '../../components/headingComponent/heading';
import styled from 'styled-components';
import { Card } from 'antd';
import DeletePermissionModal from '../../components/modals/permission/deletePermissionModal';
import PermissionModal from '../../components/modals/permission/permissionModal';
import { AntdesignTablePagination } from '../../components/antDesignTable/AntdesignTablePagination';
import CustomFilter from '../../components/customFilterWithSearchBar/customFilter';
import CustomButton from '../../components/customButton/customButton';
import { contractorTypeOptions, status } from '../../util/dropdownData';
import { contractorManagementColumns, rateMatrixManagementColumns } from '../../util/antdTableColumns';
import UpdateContractor from '../modals/contractorManagement/updateContractor';
import PaginatedSelect from '../customSelect/paginatedSelect';
import UpdateRateMatrix from '../modals/contractorManagement/updateRateMatrix';


export default function ManageRateMatrix() {

  //----------------------- Custom Hooks -----------------------//

  const { assignedPermissions } = usePermissions();

  const assignedPermissionsArray = assignedPermissions.assigned_permissions || [];
  
  //----------------------- State Variables -----------------------//

  const [rateMatrixTableData, setRateMatrixTableData] = useState({});

  const [projectOptions, setProjectOptions] = useState([]);
  const [contractorOptions, setContractorOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);

 
  const [projectSelected, setProjectSelected] = useState(null);
  const [contractorSelected, setContractorSelected] = useState(null);
  const [clientSelected, setClientSelected] = useState(null);

  const [selectedProjectData, setSelectedProjectData] = useState(null);
  const [selectedClientData, setSelectedClientData] = useState(null);
  const [selectedContractorData, setSelectedContractorData] = useState(null);
  const [selectedDebrisTypeData, setSelectedDebrisTypeData] = useState(null);

  const [selectedProjectForUpdateRateMatrix, setSelectedProjectForUpdateRateMatrix] = useState(null);
  const [selectedClientForUpdateRateMatrix, setSelectedClientForUpdateRateMatrix] = useState(null);
  const [selectedContractorForUpdateRateMatrix, setSelectedContractorForUpdateRateMatrix] = useState(null);
  const [selectedDebrisTypeForUpdateRateMatrix, setSelectedDebrisTypeForUpdateRateMatrix] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  
  const [updateRateMatrixValues, setUpdateRateMatrixValues] = useState(null);

  //---------------------- State for Modals ----------------------//

  const [updateRateMatrixModal, setUpdateRateMatrixModal] = useState(false);
  const [deleteRateMatrixModal, setDeleteRateMatrixModal] = useState(false);

  const [permissionText, setPermissionText] = useState('');
  const [permissionPopUp, setPermissionPopUp] = useState(false);


  //----------------------- Fetch Data -------------------------//

  const fetchRateMatrixData = async ( page = 1) => {
    if (projectSelected !== null && projectSelected !== undefined) {
     
      
      const query = `project=${projectSelected}&page=${page}`
      main_api.get(adminAPIsEndPoints.LIST_CONTRACTOR_RATE_MATRIX(query))
      .then((response) => {
        const result = response.data.results;
        const transformedRateMatrixData = result.map(item => ({
          sno: item?.id, 
          debris: item?.debris_type?.name?.charAt(0).toUpperCase() + item?.debris_type?.name?.slice(1) || '---',
          mileageFrom: item?.mileage_from || '---',
          mileageTo: item?.mileage_to || '---',
          diameterFrom: item?.diameter_from || '---',
          diameterTo: item?.diameter_to || '---',
          lengthFrom: item?.length_from || '---',
          lengthTo: item?.length_to || '---',
          unitFrom: item?.unit_from || '---',
          unitTo: item?.unit_to || '---',
          weightFrom: item?.weight_from || '---',
          weightTo: item?.weight_to || '---',
          unitType: item?.unit_type || '---',
          rate: item?.rate || '---',
          ...item, 
        }));
  
        setRateMatrixTableData({count: response.data.count ,data: transformedRateMatrixData});
  
      }).catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
    }else {
      setRateMatrixTableData({count: 0 ,data: []});
    };

  };

  //----------------------- Use Effect -------------------------//

  useEffect(() => {
    fetchRateMatrixData();
  }, [projectSelected]);

  //----------------------- Fetch Filter Options -------------------------//

  const fetchProjectOptions = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
    .then((response) => {
      const result = response.data.results;
      const options = result?.map((item) => ({
        ...item,
        label: item.name.charAt(0).toUpperCase() + item.name.slice(1),
        value: item.id,
      
      }));
      setProjectOptions(options);
      
    }).catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  };

  const fetchContractorOptions = async (query = '', page = 1) => {
    main_api.get(`${adminAPIsEndPoints.LIST_CONTRACTORS(query)}&page=${page}`)
    .then((response) => {
      const result = response.data.results;
      const options = result?.map((item) => {
        item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
        item.value = item.id;
        return item;
      });
      
      setContractorOptions(options);
    }).catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  };

  const fetchClientOptions = async ( page = 1) => {
    main_api.get(adminAPIsEndPoints.LIST_CLIENTS)
    .then((response) => {
      const result = response.data.results;
      const options = result?.map((item) => {
        item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
        item.value = item.id;
        return item;
      });
      setClientOptions(options);
    }).catch((error) => {
      pushNotification(error?.response?.data?.detail, "error");
    });
  };


  

  //------------------------- Use Effect for Filter Options -------------------------//

  useEffect(() => {
    fetchProjectOptions();
    fetchContractorOptions();
    fetchClientOptions();
  }, []);



  //----------------------- Functions for Filter Changes -------------------------//

  const handleProjectChange = (value) => {
    const project = projectOptions.find(item => item.value === value);
    setProjectSelected(value);
    setClientSelected(project?.client?.id);
    setContractorSelected(project?.contractor?.id);
    
  };

  const handleEventChange = (value) => {
    setContractorSelected(value);
  };

  const handleClientChange = (value) => {
    setClientSelected(value);
  };

  //----------------------- Filters -------------------------//

  // useEffect(() => {
  //   let query = '';
  //   if (projectSelected) {
  //     query += `project=${projectSelected}&`;
  //   }
  //   if (contractorSelected) {
  //     query += `event=${contractorSelected}&`;
  //   }
  //   if (clientSelected) {
  //     query += `client=${clientSelected}&`;
  //   }
  //   fetchRateMatrixData(query);
  // }, [projectSelected, contractorSelected, clientSelected]);

  //----------------------- Functions for Update & Delete Rate Matrix Modal -------------------------//

  const handleAddRow = () => {
    if (!hasPermission(assignedPermissionsArray, 'add_contractorratematrix')) {
      setPermissionText("You do not have permission to add a contractor");
      setPermissionPopUp(true)
    } else {
      setSelectedClientForUpdateRateMatrix(null);
      setSelectedProjectForUpdateRateMatrix(null);
      setSelectedContractorForUpdateRateMatrix(null);
      setSelectedDebrisTypeForUpdateRateMatrix(null);
      setUpdateRateMatrixValues(null);
      setUpdateRateMatrixModal(true);
    }
  };

  const handleEditRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_contractorratematrix')) {
      setPermissionText("You do not have permission to edit a contractor");
      setPermissionPopUp(true)
    } else if (values) {
    
      setSelectedClientForUpdateRateMatrix(values?.project?.client?.id);
      setSelectedProjectForUpdateRateMatrix(values?.project?.id);
      setSelectedContractorForUpdateRateMatrix(values?.project?.contractor?.id);
      setSelectedDebrisTypeForUpdateRateMatrix(values?.debris_type?.id);


      setSelectedClientData(values?.project?.client);
      setSelectedProjectData(values?.project);
      setSelectedContractorData(values?.project?.contractor);
      setSelectedDebrisTypeData(values?.debris_type);

      setUpdateRateMatrixValues(values);
      setUpdateRateMatrixModal(true);
    }
  };

  const handleDeleteRow = (values) => {
    if (!hasPermission(assignedPermissionsArray, 'change_contractorratematrix')) {
      setPermissionText("You do not have permission to delete a contractor");
      setPermissionPopUp(true)
    } else if (values) {
        setUpdateRateMatrixValues(values);
        setDeleteRateMatrixModal(true);
    }
  };

  //----------------------- Add and Edit Rate Matrix Functions -----------------------//

  const handleEditRateMatrix = async (values) => {
    const id = updateRateMatrixValues?.id;
    if (id && values) {
      values = {
        ...values,
        project: selectedProjectForUpdateRateMatrix,
        debris_type: selectedDebrisTypeForUpdateRateMatrix
      }

      main_api.put(adminAPIsEndPoints.UPDATE_CONTRACTOR_RATE_MATRIX(id), values)
      .then((response) => {
        if (response.status === 200) {
          pushNotification("Rate Matrix Updated Successfully!", "success");
          const query = '';
          fetchRateMatrixData(query, currentPage);
          setUpdateRateMatrixModal(false);
        }
      }).catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
    } 
  };

  const handleAddRateMatrix = async (values) => {
    if (values) {

      values = {
        ...values,
        project: selectedProjectForUpdateRateMatrix,
        debris_type: selectedDebrisTypeForUpdateRateMatrix
      }
      main_api.post(adminAPIsEndPoints.ADD_CONTRACTOR_RATE_MATRIX, values)
      .then((response) => {
        if (response.status === 201) {
          pushNotification("Rate Matrix Added Successfully!", "success");
          fetchRateMatrixData();
          setUpdateRateMatrixModal(false);
        }
      }).catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
    }
  };

  const handDeleteRateMatrix = async () => {
    const id = updateRateMatrixValues?.id;
    if (id) {
      main_api.delete(adminAPIsEndPoints.DELETE_CONTRACTOR_RATE_MATRIX(id))
      .then((response) => {
        if (response.status === 204) {
          pushNotification("Rate Matrix Deleted Successfully!", "success");
          const query = '';
          fetchRateMatrixData(query, currentPage);
          setDeleteRateMatrixModal(false);
        }
      }).catch((error) => {
        pushNotification(error?.response?.data?.detail, "error");
      });
    }
  };


  return (
    <div style={{ marginTop: '10px' }}>
    <CustomCard style={{ boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)' }}>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          <Heading text="Manage Rate Matrix" margin="0px 0px 0px 5px" fontSize="1.3rem" color="#3B3B3B" />
          <CustomButton btnText={"Add Rate Matrix"} color={"white"} onClick={handleAddRow} />
        </div>
        <div style={{display:"flex", flexDirection:"row"}}>
          <PaginatedSelect
            value={projectSelected}
            placeholder={"Select Project"}
            options={projectOptions}
            onChange={handleProjectChange}
            fetchData={fetchProjectOptions}
            style={{marginLeft:"5px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
          />
          <PaginatedSelect
            value={contractorSelected}
            placeholder={"Contractor"}
            disabled={true}
            options={contractorOptions}
            onChange={handleEventChange}
            fetchData={fetchContractorOptions}
            style={{marginLeft:"8px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
          />
          <PaginatedSelect
            value={clientSelected}
            placeholder={"Client"}
            disabled={true}
            options={clientOptions}
            onChange={handleClientChange}
            fetchData={fetchClientOptions}
            style={{marginLeft:"20px", marginBottom: "20px", position:"relative", top:"12px", left:"6px", width:"260px", height:"40px"}} 
          />
          <div
          onClick={() => {
              setProjectSelected(null);
              setContractorSelected(null);
              setClientSelected(null);
            }
          }
          style={{cursor:"pointer",color:"#EE3E41",marginLeft:"15px", marginBottom: "20px", position:"relative", top:"20px", left:"6px", width:"90px", height:"40px"}}
        >
          Reset Filters
        </div>
        </div>
        <AntdesignTablePagination 
          columns={rateMatrixManagementColumns({handleEditRow, handleDeleteRow})} 
          data={rateMatrixTableData.data}
          totalCount={rateMatrixTableData.count}
          loadPaginatedData={fetchRateMatrixData} 
          allowRowSelection={false}
          tableHeight={450}
          tableWidth={1200} 
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
    </CustomCard>
    {updateRateMatrixModal && 
      <UpdateRateMatrix 
        isModalOpen={updateRateMatrixModal} 
        title={updateRateMatrixValues ? "Edit Rate Matrix" : "Add New Rate Matrix"} 
        onFinish={updateRateMatrixValues ? handleEditRateMatrix : handleAddRateMatrix  } 
        setModalOpen={setUpdateRateMatrixModal} 
        updateRateMatrixValues={updateRateMatrixValues}
        selectedClientForUpdateRateMatrix={selectedClientForUpdateRateMatrix}
        selectedProjectForUpdateRateMatrix={selectedProjectForUpdateRateMatrix}
        selectedContractorForUpdateRateMatrix={selectedContractorForUpdateRateMatrix}
        selectedDebrisTypeForUpdateRateMatrix={selectedDebrisTypeForUpdateRateMatrix}
        selectedClientData={selectedClientData}
        selectedProjectData={selectedProjectData}
        selectedContractorData={selectedContractorData}
        selectedDebrisTypeData={selectedDebrisTypeData}
        setSelectedClientForUpdateRateMatrix={setSelectedClientForUpdateRateMatrix}
        setSelectedProjectForUpdateRateMatrix={setSelectedProjectForUpdateRateMatrix}
        setSelectedContractorForUpdateRateMatrix={setSelectedContractorForUpdateRateMatrix}
        setSelectedDebrisTypeForUpdateRateMatrix={setSelectedDebrisTypeForUpdateRateMatrix}
        setSelectedClientData={setSelectedClientData}
        setSelectedProjectData={setSelectedProjectData}
        setSelectedContractorData={setSelectedContractorData}
        setSelectedDebrisTypeData={setSelectedDebrisTypeData}
      />
    }
    {deleteRateMatrixModal &&
      <DeletePermissionModal
        isModalOpen={deleteRateMatrixModal} 
        title="Delete Rate Matrix" 
        description='Are you sure you want to delete this rate matrix? this action cannot be undone'
        deleteText='Delete'
        onDelete={handDeleteRateMatrix} 
        setModalOpen={setDeleteRateMatrixModal} 
      />
    }
    {permissionPopUp && (
      <PermissionModal 
        text={permissionText}
        onclose={() => setPermissionPopUp(false)}
        openModal={permissionPopUp}
      />
    )}
  </div>
  )
}



const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B" }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} />;
};

const CustomCard = styled(Card)`
  width: calc(100vw - 40px);
  max-width: 1570px;
  height: calc(100vh - 40px);
  max-height: 750px;
  margin-top: 40px;
  margin-left: 40px;
  background-color: white;
  
  @media (max-width: 768px) {
    width: calc(100vw - 20px);
    height: calc(100vh - 20px);
    margin: 10px;
  }
`;