import { Modal } from "antd";
// import CustomBtnWithIcon from "../buttonWithIcon";
// import HeadingComponent from "../Heading";
// import { PerformActionIcon } from "../../assets/rawSvgs/index";
import { ReactComponent as DangerIcon } from "../../../assets/rawSvg/danger.svg";
import CustomButton from "../../customButton/customButton";
import HeadingComponent from "../../headingComponent/heading";

const PermissionModal = ({
  onclose,
  openModal,
  text,
  padding = "20px",
}) => {
  return (
    <Modal open={openModal} onCancel={onclose} footer={null} width={"610px"} height={"250px"} centered>
      <div
        style={{
          padding: padding,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Heading text="Permission Manager" margin="0px 0px 0px 0px" fontSize="1.3rem" color="#1D2939" position={"relative"} bottom={"5px"} right={"132px"} />
        <DangerIcon style={{position:"relative", bottom:"28px", right:"260px"}}/>

        <HeadingComponent
          text={text}
          fontSize="0.875rem"
          fontWeight={400}
          margin="25px auto 15px auto"
        />
        <div className="d-flex justify-content-center">
          <CustomButton btnText={"Got It"} padding="10px 30px" hideIcon margin="10px" onClick={onclose} />
        </div>
      </div>
    </Modal>
  );
};

const Heading = ({ text = "", margin, fontSize = "0.75rem", color = "#3B3B3B", position, top, bottom, right, left }) => {
  return <HeadingComponent text={text} fontSize={fontSize} color={color} fontWeight={700} margin={margin} position={position} top={top} bottom={bottom} right={right} left={left} />;
};


export default PermissionModal;
