import React, { useEffect, useRef, useState } from 'react';
import MapView from '@arcgis/core/views/MapView';
import Map from '@arcgis/core/Map';
import Graphic from '@arcgis/core/Graphic';
import Point from '@arcgis/core/geometry/Point';  
import GraphicsLayer from '@arcgis/core/layers/GraphicsLayer'; // Import GraphicsLayer
import { distance } from "@arcgis/core/geometry/geometryEngine";
import SpatialReference from "@arcgis/core/geometry/SpatialReference";
import { project } from "@arcgis/core/geometry/projection";


export default function Maps({addressLatAndLong, handleReverseGeocode}) {
  const mapRef = useRef(null);
  let map = null;
  let view = null;
  let pinGraphic; // To store the pin graphic
  let graphicsLayer;

  const intializeMap = () => {

    if (mapRef.current) {
      // Create a new WebMap instance
      map = new Map({
        basemap: 'streets-navigation-vector',
      });

      view = new MapView({
        container: mapRef.current,
        map: map,
        center: [67.0011, 24.8607], // Longitude, Latitude for Karachi,
        zoom: 14,
        popup: {
          defaultPopupTemplateEnabled: true
        }
      });

      graphicsLayer = new GraphicsLayer();
      // Add the GraphicsLayer to the map
      map.add(graphicsLayer);
      let isPinDragging = false;
      const bufferDistance = 50; // Distance from edge to start panning

      view.on("drag", function(event) {
          if (isPinDragging) {
              event.stopPropagation();
          }

          if (event.action === "start") {
              const screenPoint = { x: event.x, y: event.y };
              view.hitTest(screenPoint).then(function(response) {
                  if (response.results.length && response.results[0].graphic === pinGraphic) {
                      isPinDragging = true;
                      view.container.style.cursor = "grabbing";
                  }
              });
          } else if (event.action === "end") {
              if (isPinDragging) {
                  const location = pinGraphic.geometry;
                  view.container.style.cursor = "default";
                  handleReverseGeocode(location.latitude, location.longitude);

                  const newloc = new Point({
                      longitude: location.longitude,
                      latitude: location.latitude
                  });

                  updatePin(newloc);
                  isPinDragging = false;
              }
          } else if (event.action !== "end" && isPinDragging) {
              const newLocation = view.toMap({ x: event.x, y: event.y });
              pinGraphic.geometry = newLocation;
              updatePin(newLocation);

              // Automatically pan the map when pin is near the edge
              if (event.x < bufferDistance || event.x > view.width - bufferDistance ||
                  event.y < bufferDistance || event.y > view.height - bufferDistance) {
                  
                  const screenCenter = { x: view.width / 2, y: view.height / 2 };
                  const mapCenter = view.toMap(screenCenter);

                  const targetCenter = new Point({
                      longitude: mapCenter.longitude + (newLocation.longitude - mapCenter.longitude),
                      latitude: mapCenter.latitude + (newLocation.latitude - mapCenter.latitude)
                  });

                  view.goTo(targetCenter, { animate: true, duration: 300 });
              }
          }
      });

      view.on("pointer-move", function(event) {
          if (!isPinDragging) {
              view.container.style.cursor = "default";
          }
      });

      return () => {
        if (view) {
          view.destroy();
        }
      };
    }

  }

  useEffect(() => {
    if(!map){
      intializeMap();
    }
    if(addressLatAndLong && addressLatAndLong[0]) {
      const location = new Point({
          longitude: addressLatAndLong[1],
          latitude: addressLatAndLong[0]
        });
        updatePin(location);
        view.center = location;
        view.zoom = 13;

    }

    else if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function(position) {
        const location = new Point({
          longitude: position.coords.longitude,
          latitude: position.coords.latitude
        });
        updatePin(location);
        view.center = location;
        view.zoom = 13;
      }, function(error) {
        console.error("Geolocation error: ", error);
      });
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // useEffect(() => {
  //   // graphicsLayer = new GraphicsLayer();

  //   if (mapRef.current) {
  //     // Create a new WebMap instance
  //     map = new Map({
  //       basemap: 'streets-navigation-vector',
  //     });

  //     view = new MapView({
  //       container: mapRef.current,
  //       map: map,
  //       center: [67.0011, 24.8607], // Longitude, Latitude for Karachi,
  //       zoom: 14,
  //       popup: {
  //         defaultPopupTemplateEnabled: true
  //       }
  //     });
  //     // Add the GraphicsLayer to the map
  //     map.add(graphicsLayer);


  //   // const latLng1 = { longitude: -118.2437, latitude: 34.0522 }; // Los Angeles
  //   // const latLng2 = { longitude: -118.2851, latitude: 34.0195 }; // Another point in LA

  //   // // Create Point objects for each position in WGS84 spatial reference
  //   // const point1 = new Point({
  //   //   ...latLng1,
  //   //   spatialReference: SpatialReference.WGS84
  //   // });
  //   // const point2 = new Point({
  //   //   ...latLng2,
  //   //   spatialReference: SpatialReference.WGS84
  //   // });

  //   // const projectedPoints = project([point1, point2], SpatialReference.WebMercator);

  //   // // Calculate the distance in miles between the two projected points
  //   // const [point1Projected, point2Projected] = projectedPoints;
  //   // const distanceInMiles = distance(point1Projected, point2Projected, "miles");

  //   // console.log("Distance in miles:", distanceInMiles);


  //     // Function to add or update the pin at the given location
     


  //     view.on("drag", function(event) {
  //         event.stopPropagation(); // Prevent the default drag behavior

        
  //         if (event.action !== "end") {
  //           const newLocation = view.toMap({ x: event.x, y: event.y });
  //           pinGraphic.geometry = newLocation;
  //           updatePin(newLocation);
  
  //           const screenPoint = view.toScreen(newLocation);
  //         const buffer = 50; // Distance from edge at which to start panning
  //         const panSpeed = 0.5; // Smaller values for smoother panning
  
  //         // Smoothly pan the map based on the pin's position relative to the screen edges
  //           let newCenter = view.center.clone();
    
  //           if (screenPoint.x < buffer) {
  //               newCenter.longitude -= panSpeed;
  //           } else if (screenPoint.x > view.width - buffer) {
  //               newCenter.longitude += panSpeed;
  //           }
    
  //           if (screenPoint.y < buffer) {
  //               newCenter.latitude += panSpeed; // Increase latitude as screen Y decreases
  //           } else if (screenPoint.y > view.height - buffer) {
  //               newCenter.latitude -= panSpeed; // Decrease latitude as screen Y increases
  //           }
    
  //           view.goTo({ center: newCenter }, { animate: false });
  //           } else {
  //             const location = pinGraphic.geometry;
  //             view.container.style.cursor = "default";
  //             handleReverseGeocode(location.latitude, location.longitude);
  //           }

  //     });

  //     return () => {
  //       if (view) {
  //         view.destroy();
  //       }
  //     };
  //   }
  // }, []);


  function updatePin(location) {
    if (!pinGraphic) {
      const pinSymbol = {
         type: 'picture-marker', // Use picture-marker symbol type
         url: 'https://static.arcgis.com/images/Symbols/Shapes/RedPin1LargeB.png', // URL to the red pin image
         width: '80px', // Set the width of the pin
          height: '80px', // Set the height of the pin
      };
      pinGraphic = new Graphic({
        geometry: location,
        symbol: pinSymbol
      });
      graphicsLayer.add(pinGraphic);
    } else {
      pinGraphic.geometry = location;
    }
  }

  return <div style={{ height: '70vh' }}>
  <div ref={mapRef} style={{ height: '100%' }}></div>
  </div>
}
