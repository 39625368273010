//------------------------------ Custom Hook For Projects ------------------------------//

import { useEffect, useState } from "react";
import { adminAPIsEndPoints } from "../../constants/apiEndPoints";
import { main_api } from "../../api/axiosHelper";
import { pushNotification } from "../../util/notification";

export default function useProjects() {
    
 

    //----------------------- Fetch Projects  -------------------------//
    
    const fetchProjectOptions = async (query = '', page = 1, setOptions = () => {}) => {
        main_api.get(`${adminAPIsEndPoints.LIST_PROJECT(query)}&page=${page}`)
        .then((response) => {
        const result = response.data.results;
       
        const options = result?.map((item) => {
            item.label = item.name.charAt(0).toUpperCase() + item.name.slice(1);
            item.value = item.id;
            return item;
        });
        setOptions(options);


        }).catch((error) => {
            
        pushNotification(error?.response?.data?.detail, "error");
        });
    };


    



    //----------------------- Add and Edit Project -----------------------//

    const handleEditProject = async (id,values, fetchData,closeModal,setProjectDetailsModal,currentPage,setCurrentPage) => {
        
        if (id && values) {
            
            main_api.put(adminAPIsEndPoints.UPDATE_PROJECT(id), values)
            .then((response) => {
                if (response.status === 200) {
                    pushNotification("Project Updated Successfully!", "success");
                    const query = '';
                    
                    fetchData(query,currentPage);
                    setProjectDetailsModal(false);
                    closeModal(false);
                }
            }).catch((error) => {
                pushNotification(error?.response?.data?.detail, "error");
            });
        } else {
            pushNotification("error", "No values to update!");
        }
    };

    const handleAddProject = async (values,fetchData,closeModal) => {
       if (values) {
           main_api.post(adminAPIsEndPoints.ADD_PROJECT, values)
           .then((response) => {
            
               if (response.status === 201) {
                   pushNotification("Project Added Successfully!", "success");
                   fetchData();
                   closeModal(false);
               }
           }).catch((error) => {
               pushNotification(error?.response?.data?.detail, "error");
           });
       } else {
            pushNotification("No values to add!", "error");
       }
    };

   
    
    return { fetchProjectOptions, handleEditProject, handleAddProject};
}



